import BoardAgendaStatusesEnum from "utils/enums/BoardAgendaStatuses";
import PublicAgendaStatusEnum from "utils/enums/PublicAgendaStatuses";

export const getSharedOrPublished = (boardAgendaStatus, publicAgendaStatus) => {
	const sharedStatuses = [BoardAgendaStatusesEnum().SHARED.value, BoardAgendaStatusesEnum().FINALSHARED.value];
	const publishedStatuses = [PublicAgendaStatusEnum().PUBLISHED.value, PublicAgendaStatusEnum().FINALPUBLISHED.value];

	if (boardAgendaStatus && sharedStatuses.includes(boardAgendaStatus)) {
		if (publicAgendaStatus === null) {
			return "shared";
		}
		if (publishedStatuses.includes(publicAgendaStatus)) {
			return "published";
		}
		return "shared";
	} else return null;
};

export const getWarningText = (boardStatus, publicStatus, copyOrMove, t) => {
	const status = getSharedOrPublished(boardStatus, publicStatus);
	if (status === "shared") {
		return t(`app:${copyOrMove}.toShared`);
	} else if (status === "published") {
		return t(`app:${copyOrMove}.toPublished`);
	}
};

export const handleKeyDownForToAgenda = (e) => {
	const pattern = /^outline-attachment-/;

	// Function to check if an element contains a button with ID starting with "Remove-button-"
	const hasRemoveButton = (element) => {
		const button = element?.querySelector("button[id^='Remove-button-']");
		return !!button;
	};

	// Function to skip elements based on certain conditions
	const shouldSkipElement = (element) => {
		return (
			pattern.test(element?.id) ||
			element?.classList.contains("member-only-toc-header") ||
			element?.classList.contains("outline-item-recommendation") ||
			element?.classList.contains("outline-heading-recommendation") ||
			!hasRemoveButton(element) // Skip if no "Remove-button-"
		);
	};

	const skipElement = (element) => {
		while (element && shouldSkipElement(element)) {
			element = element.nextElementSibling;
		}
		return element;
	};

	// Modified function to find the previous focusable element
	const findPreviousFocusable = (element) => {
		let previous = element.previousElementSibling;
		while (previous) {
			if (!shouldSkipElement(previous)) {
				return findLastDescendant(previous);
			}
			previous = previous.previousElementSibling;
		}
		// If no previous sibling, move up to the parent
		return element.parentNode?.closest("li");
	};

	// Function to find the last descendant of an element
	const findLastDescendant = (element) => {
		const descendants = element.getElementsByTagName("li");
		if (descendants.length > 0) {
			return descendants[descendants.length - 1];
		}
		return element;
	};

	// Function to find the next focusable element
	const findNextFocusable = (element) => {
		let next = element.nextElementSibling;
		if (next) {
			next = skipElement(next);
			if (next) {
				return findLastDescendant(next);
			}
		} else {
			let listItem = element;
			while (listItem && !listItem.nextElementSibling) {
				listItem = listItem.parentNode?.closest("li");
			}
			return skipElement(listItem?.nextElementSibling);
		}
		return null;
	};

	switch (e.key) {
		case "ArrowUp":
			let previousSibling = findPreviousFocusable(e.target);
			if (previousSibling) {
				previousSibling.focus();
			}
			break;

		case "ArrowDown":
			let nextSibling = findNextFocusable(e.target);
			if (nextSibling) {
				nextSibling.focus();
			}
			break;

		case "Enter":
			e.preventDefault();
			e.stopPropagation();

			// Click the button if present
			const buttonElement = e.target.querySelector("button");
			if (buttonElement) {
				buttonElement.click();
			}

			// Focus on the next item
			const nextItem = findNextFocusable(e.target);
			if (nextItem) {
				nextItem.focus();
			}
			break;
	}
};

export const handleKeyDownForFromAgenda = (e) => {
	const pattern = /^outline-attachment-/;

	// Function to skip elements based on certain conditions
	const shouldSkipElement = (element) => {
		return (
			pattern.test(element?.id) ||
			element?.classList.contains("member-only-toc-header") ||
			element?.classList.contains("outline-item-recommendation") ||
			element?.classList.contains("outline-heading-recommendation")
		);
	};

	const skipElement = (element) => {
		while (element && shouldSkipElement(element)) {
			element = element.nextElementSibling;
		}
		return element;
	};

	// Modified function to find the previous focusable element
	const findPreviousFocusable = (element) => {
		let previous = element.previousElementSibling;
		while (previous) {
			if (!shouldSkipElement(previous)) {
				return findLastDescendant(previous);
			}
			previous = previous.previousElementSibling;
		}
		// If no previous sibling, move up to the parent
		return element.parentNode?.closest("li");
	};

	// Function to find the last descendant of an element
	const findLastDescendant = (element) => {
		const descendants = element.getElementsByTagName("li");
		if (descendants.length > 0) {
			return descendants[descendants.length - 1];
		}
		return element;
	};

	switch (e.key) {
		case "ArrowUp":
			let previousSibling = findPreviousFocusable(e.target);
			if (previousSibling) {
				previousSibling.focus();
			}
			break;

		case "ArrowDown":
			let nextSibling = e.target.nextElementSibling;
			if (nextSibling) {
				nextSibling = skipElement(nextSibling);
				if (nextSibling) {
					nextSibling.focus();
				}
			} else {
				let listItem = e.target;
				while (listItem && !listItem.nextElementSibling) {
					listItem = listItem.parentNode?.closest("li");
				}
				const nextItem = skipElement(listItem?.nextElementSibling);
				if (nextItem) {
					nextItem.focus();
				}
			}
			break;

		case "Enter":
			e.preventDefault();
			e.stopPropagation();
			const inputElement = e.target.querySelector("input");
			if (inputElement) {
				inputElement.click();
			}
			break;
	}
};

export const handleKeyDownForSwitch = (event) => {
	if (event.key === "Enter") {
		const inputElement = event.target.querySelector("input");
		inputElement?.click();
	}
};
