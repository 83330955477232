import React, { useState, useEffect, useRef } from "react";
import LinearStepper from "./utilsComponent/LinearStepper";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import MeetingsTab from "./Components/MeetingsTab";
import HelpMenu from "../../views/HelpMenu/HelpMenu";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import ToAgendaListPage from "./ToAgendaListPage";
import { useNavigate } from "react-router-dom";
import { useMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FromAgendaListPage from "./Components/FromAgendaListPage";
import {
	assignNewGuidToCopiedItems,
	createObjectsToInsert,
	insertItemsIntoRightAgenda,
	markItemsDeletedFromLeftAgenda,
	recordOriginalItemOrder,
	setWarningForMemberOnlyItems,
} from "./utils/copyMovefunctions";
import cloneDeep from "lodash/cloneDeep";
import {
	setFinalRightMeetingAgenda,
	resetState,
	confirmCopyOrMove,
	setWarningMessage,
	resetLeftAgendaObject,
	setFirstDeleteButtonIndex,
} from "redux/NewCopyAndMove/actions";
import { reOrderItemArray } from "views/MeetingEditor/functions/utils";
import { useWidthDown } from "atlas/utils/useWidth";
import clsx from "clsx";
import { focusColor } from "atlas/assets/jss/shared";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import { useTranslation } from "react-i18next";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { useSnackbar } from "notistack";

const useStyles = makeStyles({
	headerContainer: {
		display: "flex",
		backgroundColor: "#2f3b4d",
		height: "56px",
		justifyContent: "space-between",
	},
	headerTitle: {
		color: "#FFFFFF",
		fontSize: "16px",
		fontWeight: "400",
		display: "flex",
		alignItems: "center",
	},
	headerItems: {
		display: "flex",
		alignItems: "center",
	},
	tabFocusStyling: {
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
		},
	},
	containerStyle: {
		backgroundColor: "#f5f8f9",
	},
	copyMoveMainDiv: {
		paddingTop: "20px",
		margin: "auto",
		height: "calc(98vh - 128px)",
		maxWidth: "768px",
		backgroundColor: "white",
	},
	navbarButtons: {
		marginTop: "13px",
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "2px",
			borderRadius: "4px",
		},
	},
	copyMoveMainContainer: {
		padding: "0px 40px 0px 40px",
	},
	copyMoveMainContainerMobile: {
		padding: "0px 12px 0px 12px",
	},
	loadingIndicatorContainer: {
		position: "absolute",
		top: 0,
		width: "100%",
		height: "100%",
		backgroundColor: "rgba(255, 255, 255, 0.6)",
		zIndex: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	headerBackgroundPosition: {
		display: "flex",
		background: "#2f3b4d",
		width: "100%",
		height: "48px",
		position: "absolute",
		zIndex: 1,
	},
	footerButtonContainer: {
		backgroundColor: "#FFFFFF",
		boxShadow: "0px -1px 8px 0px #80808024",
		height: "72px",
	},
	footerInnerContainer: {
		maxWidth: "768px",
		margin: "auto",
	},
});

const LayoutContainer = () => {
	const { params: { id, text } = {} } = useMatch({ path: "/meeting/:text/:id", end: true }) || {};

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const isOverflow = queryParams.get("isOverflow") === "true";

	const classes = useStyles();
	const navigate = useNavigate();
	const widthDownMd = useWidthDown("sm");
	const [stageNumber, setStageNumber] = useState(1);
	const [loading, setLoading] = useState(false);
	const [isCreateMeeting, setIsCreateMeeting] = useState(false);
	const { t } = useTranslation();
	const [continueDisabled, setContinueDisabled] = useState(true);
	const [layoutControlContinue, setLayoutControlContinue] = useState(null);
	const [thisReference, setThisReference] = useState(null);
	const { closeSnackbar } = useSnackbar();

	const isCopy = text === "copy";

	const {
		overallSelectedMeetingItems,
		leftAgendaObject: objectForAgendaItems,
		rightAgendaMap: overallMapForRightAgendaItems,
		originalRightMeetingAgendaItems,
		originalLeftMeetingAgendaItems,
		sourceMeeting,
		finalRightMeetingAgendaItems,
		selectedMeeting,
		selectedMeetingId,
		creatingMeeting,
	} = useSelector((state) => state.newCopyAndMoveReducer);
	const steps = isOverflow
		? [t("app:stepsCopyMove.step1"), t("app:stepsCopyMove.step3")]
		: [t("app:stepsCopyMove.step1"), t("app:stepsCopyMove.step2"), t("app:stepsCopyMove.step3")];
	const dispatch = useDispatch();

	useEffect(() => {
		if (stageNumber === 1) {
			setContinueDisabled(!(selectedMeetingId || isCreateMeeting));
		}
	}, [selectedMeetingId, stageNumber, isCreateMeeting]);

	useEffect(() => {
		if (stageNumber === 2) {
			checkAnyItemSelected(overallSelectedMeetingItems, true, false);
		}
	}, [overallSelectedMeetingItems]);
	useEffect(() => {
		if (stageNumber === 3) {
			checkItemSelectedToAgendaPage(finalRightMeetingAgendaItems);
		}
	}, [finalRightMeetingAgendaItems]);

	const callBackforFromAgendaListPage = () => {
		checkAnyItemSelected(overallSelectedMeetingItems);
	};

	const callBackforToAgendaListPage = () => {
		checkItemSelectedToAgendaPage(finalRightMeetingAgendaItems);
	};

	const handleCancel = () => {
		telemetryAddEvent(`Copy/Move - ${text} - Cancel`);
		dispatch(resetState());
		dispatch(resetLeftAgendaObject());
		navigate(`/meeting/detailsV2/${id}`);
	};

	const checkCreateMeetingTab = (value) => {
		setIsCreateMeeting(value);
	};

	const dismissSnackbar = () => {
		closeSnackbar();
		dispatch(setSnackbarOptions({}));
	};

	const getHeaderTitle = () => {
		if (originalLeftMeetingAgendaItems.meeting) {
			if (text === "copy") {
				return "Copy items from" + " " + (sourceMeeting?.cleanName || originalLeftMeetingAgendaItems?.meeting?.cleanName);
			} else if (text === "move")
				return "Move items from" + " " + (sourceMeeting?.cleanName || originalLeftMeetingAgendaItems?.meeting?.cleanName);
		}
	};

	const checkAnyItemSelected = (obj) => {
		const hasSelectedItem = (innerObj) => {
			if (typeof innerObj === "object" && innerObj !== null) {
				// Check if `enabled` is true or any nested value is true
				return (
					innerObj.enabled || Object.values(innerObj).some((value) => (typeof value === "object" ? hasSelectedItem(value) : value === true))
				);
			}
			return false;
		};
		// Check the entire object
		if (Object.keys(obj).length > 0 && Object.values(obj).some((innerObj) => hasSelectedItem(innerObj))) {
			setContinueDisabled(false);
		} else {
			setContinueDisabled(true);
		}
	};

	const checkItemSelectedToAgendaPage = (data) => {
		const copiedIndex = data.findIndex((item) => item.hasOwnProperty("copied") && item.copied === true);

		if (copiedIndex !== -1) {
			setContinueDisabled(false);
			dispatch(setFirstDeleteButtonIndex(copiedIndex));
		} else {
			setContinueDisabled(true);
		}
	};

	const redirectToEditPage = () => {
		telemetryAddEvent(`Copy/Move - Edit Meeting - After ${text}`);
		navigate(`/meeting/detailsV2/${selectedMeetingId}`);
		dismissSnackbar();
	};

	const getConfirmCopyOrMoveButtonData = () => {
		return {
			buttonText: t("app:buttons.confirmText", { text: text }),
			actionFunction: () => {
				telemetryAddEvent(`Copy/Move - Items - Confirm ${text}`);
				let dataForMeeting = {};
				const finalRightMeetingWithGuids = cloneDeep(finalRightMeetingAgendaItems);
				let movedLeftItems = [];
				if (isCopy) {
					assignNewGuidToCopiedItems(
						finalRightMeetingWithGuids.filter((item) => item.copied),
						isCopy,
					);
				} else {
					let leftAgendaItems = cloneDeep(originalLeftMeetingAgendaItems.items);
					leftAgendaItems && recordOriginalItemOrder(leftAgendaItems);
					movedLeftItems = markItemsDeletedFromLeftAgenda(leftAgendaItems, overallSelectedMeetingItems, objectForAgendaItems);
					const agendaNumberingProps = {
						props: { agendaNumbering: originalLeftMeetingAgendaItems.agendaNumbering },
						itemIdsToUpdate: [],
					};
					reOrderItemArray(
						movedLeftItems.filter((item) => !item.removed),
						agendaNumberingProps,
						originalLeftMeetingAgendaItems.customNumbering,
					);
					assignNewGuidToCopiedItems(
						finalRightMeetingWithGuids.filter((item) => item.copied),
						isCopy,
					);
				}
				dataForMeeting = {
					newMeetingId: originalRightMeetingAgendaItems?.meeting?.id,
					newItems: finalRightMeetingWithGuids,
					oldItems: movedLeftItems,
				};
				setLoading(true);
				dispatch(confirmCopyOrMove(dataForMeeting, id))
					.then((res) => {
						if (res.status === 200) {
							setLoading(false);
							navigate(`/meeting/detailsV2/${id}`);
							let option = notifierMessage(
								t(`app:${text}.${isCopy ? "copySuccess" : "moveSuccess"}`, { meetingName: selectedMeeting.cleanName }),
								"success",
								null,
								null,
								redirectToEditPage,
								t("snackbarButton:editMeeting"),
								true,
							);
							dispatch(resetState());
							dispatch(resetLeftAgendaObject());
							dispatch(setSnackbarOptions(option));
						}
					})
					.catch((error) => {
						if (error.status === 403 && error.response.body.Message === "Access Denied") {
							let option = notifierMessage("Access Denied", "error");
							dispatch(setSnackbarOptions(option));
							setLoading(false);
							navigate(`/meeting/detailsV2/${id}`);
						} else {
							let option = notifierMessage("Something went wrong.Please Try again", "error");
							dispatch(setSnackbarOptions(option));
							setLoading(false);
							navigate(`/meeting/detailsV2/${id}`);
						}
					});
			},
		};
	};

	const getContinueButtonData = () => {
		let buttonData = {
			buttonText: "",
			actionFunction: () => {},
		};
		switch (stageNumber) {
			case 1:
				if (!isCreateMeeting) {
					buttonData = {
						buttonText: t("app:buttons.continue"),
						actionFunction: () => {
							handleContinue();
						},
					};
				} else if (isCreateMeeting) {
					buttonData = {
						buttonText: t("app:buttons.continue"),
						actionFunction: () => {
							layoutControlContinue && layoutControlContinue(thisReference);
						},
					};
				}
				break;
			case 2:
				buttonData = isOverflow
					? getConfirmCopyOrMoveButtonData()
					: {
							buttonText: t("app:buttons.continue"),
							actionFunction: () => {
								let itemsToInsert = createObjectsToInsert(
									overallSelectedMeetingItems,
									objectForAgendaItems,
									overallMapForRightAgendaItems,
									isCopy,
									dispatch,
									setWarningMessage,
								);
								let newRightAgendaItems = cloneDeep(originalRightMeetingAgendaItems.items);
								newRightAgendaItems && recordOriginalItemOrder(newRightAgendaItems);
								let tempArray = insertItemsIntoRightAgenda(itemsToInsert, newRightAgendaItems);
								let agendaNumberingProps = {
									props: { agendaNumbering: originalRightMeetingAgendaItems.agendaNumbering },
									itemIdsToUpdate: [],
								};
								reOrderItemArray(tempArray, agendaNumberingProps, originalRightMeetingAgendaItems.customNumbering);
								setWarningForMemberOnlyItems(tempArray, isCopy, dispatch, setWarningMessage);
								dispatch(setFinalRightMeetingAgenda(tempArray));
								handleContinue();
							},
						};
				break;
			case 3:
				buttonData = getConfirmCopyOrMoveButtonData();
				break;
		}
		return buttonData;
	};
	const buttonData = getContinueButtonData();

	const handleContinue = () => {
		if (steps.length !== stageNumber) {
			setStageNumber((prevStageNumber) => prevStageNumber + 1);
		}
	};

	const handleBack = () => {
		setStageNumber((prevStageNumber) => prevStageNumber - 1);
	};

	return sourceMeeting || originalLeftMeetingAgendaItems ? (
		<div className={classes.containerStyle}>
			{(loading || creatingMeeting) && (
				<>
					<div className={classes.headerBackgroundPosition}></div>
					<div id="loading" className={classes.loadingIndicatorContainer}>
						<Box>
							<CircularProgressIndicator size={30} minHeight={"30"} style={{ color: "#2f3b4d" }} />
						</Box>
					</div>
				</>
			)}
			<div id="header" className={classes.headerContainer}>
				<Box mr={2} className={classes.headerItems}>
					<AccessibleIconButton
						id="back"
						color="inherit"
						aria-label="back"
						onClick={handleCancel}
						iconName="expand-left"
						dataCy="back-button-header"
						tooltipText={""}
						className={classes.tabFocusStyling}
						isHeader
						disableRipple
					/>
				</Box>
				<div className={classes.headerTitle}>{getHeaderTitle()}</div>
				<div className={classes.headerItems}>
					<HelpMenu className={classes.tabFocusStyling} disableRipple />
				</div>
			</div>

			<div className={classes.copyMoveMainDiv}>
				<div className={clsx({ [classes.copyMoveMainContainer]: !widthDownMd, [classes.copyMoveMainContainerMobile]: widthDownMd })}>
					<LinearStepper steps={steps} stepNumber={stageNumber} />
					{stageNumber === 1 && (
						<div>
							<MeetingsTab
								afterMeetingCreated={handleContinue}
								copyOrMove={text}
								parentMeetingId={id}
								isCopy={isCopy}
								checkCreateMeetingTab={checkCreateMeetingTab}
								setLayoutControlContinue={setLayoutControlContinue}
								setThisReference={setThisReference}
							/>
						</div>
					)}
					{stageNumber === 2 && (
						<FromAgendaListPage
							parentMeetingId={id}
							callBackforFromAgendaListPage={callBackforFromAgendaListPage}
							copyOrMove={text}
							isOverflow={isOverflow}
							isCopy={isCopy}
							callBackforToAgendaListPage={callBackforToAgendaListPage}
						/>
					)}
					{stageNumber === 3 && <ToAgendaListPage callBackforToAgendaListPage={callBackforToAgendaListPage} copyOrMove={text} />}
				</div>
			</div>

			<div className={classes.footerButtonContainer}>
				<div className={classes.footerInnerContainer}>
					<Box sx={{ display: "flex", flexDirection: "row", padding: "0px 40px 0px 40px" }}>
						{stageNumber !== 1 && (
							<Button
								variant="outlined"
								onClick={handleBack}
								sx={{ mr: 1 }}
								className={classes.navbarButtons}
								aria-label="Back"
								tabIndex={2}
							>
								{t("app:buttons.back")}
							</Button>
						)}
						<Box sx={{ flex: "1 1 auto" }} />
						<Button onClick={handleCancel} className={classes.navbarButtons} aria-label="Cancel" tabIndex={1}>
							{t("app:buttons.cancel")}
						</Button>
						<Button
							variant="contained"
							color="primary"
							disabled={continueDisabled}
							onClick={buttonData.actionFunction}
							className={classes.navbarButtons}
							aria-label={buttonData.buttonText}
							tabIndex={0}
							aria-disabled={false}
						>
							{buttonData.buttonText}
						</Button>
					</Box>
				</div>
			</div>
		</div>
	) : (
		<CircularProgressIndicator />
	);
};

export default LayoutContainer;
