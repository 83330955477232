import React from 'react';
import Parser, { domToReact } from 'html-react-parser';
import { stripHtml } from 'utils/processHtml';
import clsx from 'clsx';

import { ListItem, MenuItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

import calendarIconStyles, { CALENDAR_ICON_SMALL } from '../../assets/jss/components/calendarIconStyles';
import cardStyles from '../../assets/jss/components/cardStyle';
import { grayColor, focusColor } from '../../assets/jss/shared';
import CalendarIcon from '../CalendarIcon/CalendarIcon';
import AccessibleIconButton from '../Buttons/AccessibleIconButton';
import NonModalMenu from '../Menu/NonModalMenu';
import Tooltip from '../Tooltip/Tooltip';
import { getFieldValue } from '../../utils/itemData';
import { useWidthDown } from '../../utils/useWidth';

const useCardStyles = makeStyles(cardStyles);
const useCalendarIconStyles = makeStyles(calendarIconStyles);
const useStyles = makeStyles(() => ({
	listItem: {
		display: 'flex',
		padding: '12px 8px 12px 2px',
		borderBottom: `1px solid ${grayColor[4]}`,
		boxSizing: 'border-box',
	},
	listItemWithRadioButtons: {
		justifyContent: 'space-between',
	},
	mainContent: {
		display: 'flex',
		maxWidth: '100%',
		minWidth: '0px',
		flexGrow: (props) => (!props.hasSecondaryContent || props.widthDownMd ? '1' : undefined),
		flexShrink: '1',
		flexWrap: 'wrap',
		padding: '0px 8px',
		boxSizing: 'border-box',
	},
	link: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: '100%',
		minWidth: '0',
	},
	textDetails: {
		maxWidth: '100%',
		minWidth: '0',
		flexGrow: '1',
		flexShrink: '1',
	},
	secondaryContent: {
		flexGrow: '1',
		flexShrink: '0',
		padding: '0px 8px',
		boxSizing: 'border-box',
	},
	secondaryContentMobile: {
		width: '100%',
	},
	additionalContent: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		flexShrink: '0',
		padding: '0px 8px',
		boxSizing: 'border-box',
	},
	overflow: {
		width: '32px',
	},
	tabFocusStyling: {
		'&:focus-visible': {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: '-2px',
			borderRadius: '4px',
		},
	},
	cursorStyling: {
		cursor: 'default',
	},
}));

const ListCard = (props) => {
	const {
		item = {},
		idField = 'id',
		urlField,
		dateField,
		nameField = 'name',
		subTitleField,
		reserveSubTitleSpace = true,
		itemClick,
		disableLink = false,
		secondaryContent,
		additionalContent,
		menuOptions,
		overflowTooltip,
		menuAnchor,
		handleToggleMenu,
		classes: overrideClasses = {},
		component = 'li',
		extraContent,
		ellipsedTruncatedText,
		isEllipsed = false,
		isCopyMove = false,
		radioButton = () => {},
		showRadioButton = true,
		handleRadioButtonChange,
		tabIndex,
		handleKeyDown,
	} = props;
	const widthDownMd = useWidthDown('md');
	const calendarIconClasses = useCalendarIconStyles({ size: CALENDAR_ICON_SMALL, rightSpacing: '12px' });
	const cardClasses = useCardStyles();
	const classes = useStyles({ hasSecondaryContent: !!secondaryContent, widthDownMd, isCopyMove });

	const handleOpenMenu = (e) => {
		handleToggleMenu(e.currentTarget);
	};

	const handleMenuClose = () => {
		handleToggleMenu(null);
	};

	const parserOptions = {
		replace: (node) => {
			if (!node.attribs) return;
			if (['img', 'br'].includes(node.name) && node.attribs.style) {
				node.attribs.style = '';
			}
			if (node.attribs.style === 'margin-top: 0; margin-bottom: 0;') {
				// eslint-disable-next-line consistent-return
				return <node.name>{domToReact(node.children, parserOptions)}</node.name>;
			}
		},
	};

	const itemId = getFieldValue(item, idField);
	const itemDate = getFieldValue(item, dateField);

	const getMainContent = () => (
		<>
			{itemDate && !widthDownMd && (
				<CalendarIcon
					className={isCopyMove ? overrideClasses.calendarIconStyle : calendarIconClasses.root}
					date={itemDate}
					data-cy={`item-date-${itemId}`}
				/>
			)}
			<div className={clsx(classes.textDetails, overrideClasses.textDetails)}>
				<div className={clsx(cardClasses.title, cardClasses.titleEllipsis, cardClasses.agendaItemTitle)}>
					{extraContent && extraContent()}
					{isEllipsed ? (
						<Tooltip disableHoverListener={!ellipsedTruncatedText.isTruncated} title={stripHtml(getFieldValue(item, nameField))}>
							<span className={clsx({ [classes.cursorStyling]: isCopyMove })}>
								{ellipsedTruncatedText.isTruncated
									? ellipsedTruncatedText.text
									: Parser(stripHtml(getFieldValue(item, nameField)), parserOptions)}
							</span>
						</Tooltip>
					) : (
						<span className={clsx({ [classes.cursorStyling]: isCopyMove })}>{Parser(stripHtml(getFieldValue(item, nameField)), parserOptions)}</span>
					)}
				</div>
				<div className={cardClasses.subTitle}>{getFieldValue(item, subTitleField) || (reserveSubTitleSpace ? <>&nbsp;</> : undefined)}</div>
			</div>
		</>
	);
	const getSecondaryContent = (mobile) => (
		<div
			className={clsx({
				[classes.secondaryContent]: !mobile,
				[overrideClasses.secondaryContent]: !mobile,
				[classes.secondaryContentMobile]: mobile,
				[overrideClasses.secondaryContentMobile]: mobile,
			})}
		>
			{secondaryContent}
		</div>
	);

	return (
		<>
			{menuAnchor && (
				<NonModalMenu id="item-actions" className="overflow-menu" anchorEl={menuAnchor} keepMounted open onClose={handleMenuClose}>
					{menuOptions.map((option) => (
						<Tooltip PopperProps={{ disablePortal: true }} placement="left" title={option.tooltip} key={`${itemId}-${option.label}`}>
							<span>
								<MenuItem disabled={false} onClick={option.actionFunction} data-cy={`item-menu-option-${option['data-cy']}-${itemId}`}>
									{option.label}
								</MenuItem>
							</span>
						</Tooltip>
					))}
				</NonModalMenu>
			)}

			<ListItem
				className={clsx(classes.listItem, overrideClasses.listItem, {
					[classes.listItemWithRadioButtons]: isCopyMove,
					[classes.tabFocusStyling]: isCopyMove,
				})}
				key={itemId}
				component={component}
				data-cy={`item-${itemId}`}
				tabIndex={tabIndex && tabIndex}
				onKeyDown={handleKeyDown}
			>
				<div className={clsx(classes.mainContent, overrideClasses.mainContent)}>
					{!disableLink ? (
						<a className={clsx('noStyle', classes.link, overrideClasses.link)} href={getFieldValue(item, urlField)} onClick={itemClick}>
							{getMainContent()}
						</a>
					) : (
						getMainContent()
					)}
					{secondaryContent && widthDownMd ? getSecondaryContent(true) : null}
				</div>
				{secondaryContent && !widthDownMd ? getSecondaryContent() : null}
				{(menuOptions && menuOptions.length > 0) || additionalContent ? (
					<div className={clsx(classes.additionalContent, overrideClasses.additionalContent)}>
						{additionalContent}
						<div className={classes.overflow}>
							{menuOptions && menuOptions.length > 0 && (
								<AccessibleIconButton
									iconName="more"
									color="inherit"
									aria-label={overflowTooltip}
									tooltipText={overflowTooltip}
									onClick={handleOpenMenu}
									dataCy={`item-overflow-menu-${itemId}`}
								/>
							)}
						</div>
					</div>
				) : null}
				{isCopyMove && showRadioButton && radioButton(item)}
			</ListItem>
		</>
	);
};

export default ListCard;
