import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";

import makeStyles from "@mui/styles/makeStyles";
import { ListItem, ListItemText, Typography } from "@mui/material";

import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import telemetryAddEvent from "utils/telemetryAddEvent";
import TOCAttachments from "./TOCAttachments";
import TOCPrimaryText from "./TOCPrimaryText";
import useAttachmentsHighlight from "../PdfViewerNew/utils/useAttachmentsHighlight";
import { useDroppable } from "@dnd-kit/core";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import MoveButtons from "./CommonComponents/MoveButtons";
import { Presentation } from "../Icons";
import { isSingleLine, getElement, handleKeyDownDND } from "./CommonComponents/tocUtils";
import TocContentWrapper from "./CommonComponents/TocContentWrapper";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);

const TOCMeetingItem = (props) => {
	const {
		item: { fields },
		item,
		isMemberOnlySection,
		isConsentSection,
		isPublicCommentSection,
		toggleTableOfContent = () => {},
		isSmallDevice,
		parserOptions,
		isClosedMeeting,
		idPrefix = "outline",
		button,
		selected,
		setActive,
		telemetryPage,
		setDataForAttachmentDocument,
		setDataForOutlineItem,
		isPdfSidebar,
		selectedId,
		openGoalsPopup,
		canDrag = false,
		canDrop = false,
		isLastChildItem = false,
		isActive = false,
		setActiveId = () => {},
		moveItem = () => {},
		renderControl,
		index,
		getNextAvailablePosition = () => {},
		isAgenda = false,
		isMinutes = false,
		presenting = false,
		clone = false,
	} = props;
	const dispatch = useDispatch();
	const tableOfContentsClasses = useTableOfContentsStyles({ lineLimit: 2 });
	const tableOfContentsClassesLimit1 = useTableOfContentsStyles({ lineLimit: 1 });
	const [clickedOnAttachment, setClickedOnAttachment, clickedOnAttachmentInSidePanel] = useAttachmentsHighlight(false);
	const [hoverStatus, setHoverStatus] = useState(false);
	const [arrowNavigation, setArrowNavigation] = useState(true);
	const { firstDeleteButtonIndex } = useSelector((state) => state.newCopyAndMoveReducer);

	const dragProps = canDrag ? { dragId: item.guid, dragComponent: "div" } : {};

	const droppable = useDroppable({ id: item.guid });
	const { setNodeRef } = canDrop ? droppable : {};

	const handleKeyDown = (e) => {
		if (e.key === "ArrowDown" || e.key === "ArrowUp") {
			setArrowNavigation(true);
		} else {
			setArrowNavigation(false);
		}
	};

	const handleBlur = () => {
		if (arrowNavigation) {
			setHoverStatus(false);
		}
	};

	const handleMove = (item, isMoveUp) => {
		setHoverStatus(false);
		moveItem(item, isMoveUp);
	};

	const ConditionForNonOutline = !canDrag && !(isAgenda || isMinutes);
	const ConditionForOutline = canDrag || isAgenda || isMinutes;

	return (
		!item.deleted && (
			<TocContentWrapper
				condition={isAgenda || isMinutes}
				wrapper="span"
				wrapperProps={{
					id: `${item.guid}-toc-meeting-item`,
					ref: setNodeRef,
					onKeyDown: handleKeyDown,
				}}
			>
				<ListItem
					id={`${idPrefix}-${item.guid}`}
					data-cy={`${idPrefix}-${item.guid}`}
					className={clsx(tableOfContentsClasses.listItem, {
						[tableOfContentsClasses.closedChanged]: item.original && item.original.closed && !item.fields.Closed.Value,
					})}
					button={button}
					role=""
					component={"li"}
					disableGutters
					divider
					classes={{
						root: clsx(
							canDrag ? "candrag-outline-item-secondary" : "outline-item-secondary",
							ConditionForNonOutline && tableOfContentsClasses.tabFocusStyling,
							ConditionForNonOutline && item.copied && renderControl?.isAnimationAdded(document.getElementById(`${idPrefix}-${item.guid}`))
								? "transition-animation"
								: item.copied && renderControl
									? "transition-animation-added"
									: "",
							{
								"toc-padding": hoverStatus && canDrag,
								"toc-active-item": isActive && canDrag,
								"member-only-section": isMemberOnlySection,
								"consent-section-outline": isConsentSection,
								"public-comment-section-outline-pdfsidebar": isPublicCommentSection && isPdfSidebar,
								"public-comment-section-outline": isPublicCommentSection && !isPdfSidebar,
								"closed-meeting": isClosedMeeting,
								"member-only-section-last-child-item":
									isLastChildItem && item.attachments.length === 0 && isMemberOnlySection && ConditionForOutline,
								pdfSideBar: isPdfSidebar,
								hyperlinkAttachment: isPdfSidebar && selectedId && selectedId.includes(item.guid) && !clickedOnAttachment,
								[tableOfContentsClasses.backgroundColorForSelected]:
									ConditionForNonOutline && renderControl && renderControl.highlightCheck && renderControl.highlightCheck(item),
								[tableOfContentsClasses.itemValueAndTextGapping]: ConditionForNonOutline && renderControl,
							},
						),
					}}
					onMouseOver={() => setHoverStatus(true)}
					onMouseOut={() => setHoverStatus(false)}
					onFocus={() => setHoverStatus(true)}
					onBlur={handleBlur}
					onClick={
						button && isPdfSidebar
							? (e) => {
									setDataForOutlineItem(item);
									setClickedOnAttachment(false);
									dispatch(setActive(item.guid, true));
									setActiveId(item.guid);
								}
							: button
								? () => {
										telemetryAddEvent(`${telemetryPage} - Outline navigation`);

										dispatch(setActive(item.guid, true));
										setActiveId(item.guid);
										if (isSmallDevice) {
											toggleTableOfContent();
										}
									}
								: undefined
					}
					selected={selected && selected.includes(item.guid)}
					tabIndex={ConditionForOutline ? 0 : firstDeleteButtonIndex ? (firstDeleteButtonIndex === index ? 0 : -1) : index === 0 ? 0 : -1}
					onKeyDown={(e) => renderControl && renderControl?.keyDownAction?.(e, section)}
				>
					<>
						{canDrag && (
							<Draggable {...dragProps} handleKeyDown={handleKeyDownDND}>
								<DragHandle role="button" />
							</Draggable>
						)}
						<Typography variant="h5" component="span" className="outline-number">
							{fields.Number.Value}
						</Typography>
						<ListItemText
							className={clsx({
								[tableOfContentsClasses.limitLines]: canDrag ? !isSingleLine(getElement(`${idPrefix}-${item.guid}`)) : true,
								[tableOfContentsClassesLimit1.limitLines]: canDrag && isSingleLine(getElement(`${idPrefix}-${item.guid}`)),
							})}
							primaryTypographyProps={{ variant: "h5" }}
							primary={
								<TOCPrimaryText
									text={fields.Name.Value}
									parserOptions={parserOptions}
									showTruncationTooltip
									truncationThreshold={isPdfSidebar ? 23 : 50}
									isPdfSidebar={isPdfSidebar}
								></TOCPrimaryText>
							}
						/>
						{hoverStatus && canDrag && (
							<MoveButtons
								moveItem={handleMove}
								item={item}
								showMoveUp={getNextAvailablePosition(item, true).nextPosition !== null}
								showMoveDown={getNextAvailablePosition(item, false).nextPosition !== null}
								setHoverStatus={setHoverStatus}
							/>
						)}
						{presenting && !hoverStatus && selected && selected.includes(item.guid) && <Presentation />}
					</>
					{renderControl?.isBoardNotesPresent && renderControl.isBoardNotesPresent(item)}
					{renderControl?.isCheckBoxVisible && renderControl.isCheckBoxVisible(item) && renderControl.rendercomponent(item, index)}
				</ListItem>

				{!clone && (
					<TOCAttachments
						attachments={item.attachments}
						item={item}
						itemGuid={item.guid}
						isMemberOnlySection={isMemberOnlySection}
						isConsentSection={isConsentSection}
						isPublicCommentSection={isPublicCommentSection}
						toggleTableOfContent={toggleTableOfContent}
						telemetryPage={telemetryPage}
						setDataForAttachmentDocument={setDataForAttachmentDocument}
						isPdfSidebar={isPdfSidebar}
						setActive={setActive}
						clickedOnAttachmentInSidePanel={clickedOnAttachmentInSidePanel}
						isAgendaItem
						selectedId={selectedId}
						openGoalsPopup={openGoalsPopup}
						isLastChildItem={isLastChildItem}
						canDrag={canDrag}
						renderControl={renderControl}
					/>
				)}
			</TocContentWrapper>
		)
	);
};

export default React.memo(TOCMeetingItem);
