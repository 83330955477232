import React from "react";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

import { InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";

import { grayColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";

const PREFIX = "SearchInput";
const classes = {
	search: `${PREFIX}-search`,
	searchInput: `${PREFIX}-searchInput`,
	iconClickable: `${PREFIX}-iconClickable`,
	clearSearch: `${PREFIX}-clearSearch`,
	hiddenClearSearch: `${PREFIX}-hiddenClearSearch`,
};
const StyledOutlinedInput = styled(OutlinedInput)(({ _theme }) => ({
	[`&.${classes.search}`]: {
		margin: "0",
	},
	[`& .MuiInputBase-root.${classes.searchInput}`]: {
		margin: "0",
	},
}));
const StyledInputAdornment = styled(InputAdornment)(({ _theme }) => ({
	[`&.${classes.iconClickable}`]: {
		cursor: "pointer",
	},
	[`&.${classes.clearSearch}`]: {
		marginRight: "8px",
	},
	[`&.${classes.hiddenClearSearch}`]: {
		visibility: "hidden",
	},
}));

const SearchInput = (props) => {
	const { className, placeholder, onFocus, onBlur, onIconClick, onClearClick, showClearIcon, copyOrMove, ...otherProps } = props;
	const { t } = useTranslation();
	const onClearSearch = () => {
		otherProps?.onClearSearchClick();
	};

	return (
		<StyledOutlinedInput
			{...otherProps}
			noDefaultClassName
			className={clsx(classes.search, className)}
			placeholder={placeholder || t("search.placeholder")}
			InputProps={{
				className: classes.searchInput,
				startAdornment: (
					<StyledInputAdornment
						title={onIconClick != null ? t("tooltips.clickSearch") : undefined}
						className={clsx({ [classes.iconClickable]: onIconClick != null })}
						onClick={onIconClick}
						position="start"
					>
						<Icon name="search" size="20" />
					</StyledInputAdornment>
				),
				endAdornment: (
					<StyledInputAdornment
						className={clsx(classes.iconClickable, classes.clearSearch, { [classes.hiddenClearSearch]: !showClearIcon })}
						position="end"
						onClick={onClearSearch}
					>
						<AccessibleIconButton
							smallIcon
							id="clearSearch"
							color="inherit"
							aria-label="clearSearch"
							onClick={onClearClick}
							iconName="close-no-circle"
							dataCy="clearSearchAdornment"
							tooltipText={t("tooltips.clearSearch")}
							iconColor={grayColor[0]}
							iconSize="14"
							tabIndex={copyOrMove ? -1 : 0}
						/>
					</StyledInputAdornment>
				),
			}}
			inputProps={{
				"aria-label": t("search.placeholder"),
				onFocus,
				onBlur,
			}}
		/>
	);
};

export default SearchInput;
