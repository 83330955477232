import React from "react";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { focusColor } from "atlas/assets/jss/shared";

const AntSwitch = styled((props) => <Switch {...props} />)(({ theme }) => ({
	width: 46,
	height: 24,
	padding: 0,
	display: "flex",
	"&:active": {
		"& .MuiSwitch-thumb": {
			width: 15,
		},
		"& .MuiSwitch-switchBase.Mui-checked": {
			transform: "translateX(23px)",
		},
	},
	"& .MuiSwitch-switchBase": {
		padding: 3.3,
		borderRadius: 50,
		"&.Mui-checked": {
			transform: "translateX(20px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				opacity: 1,
				backgroundColor: "#455D82",
			},
		},
	},
	"& .MuiSwitch-thumb": {
		boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
		width: 17,
		height: 17,
		borderRadius: 15,
		backgroundColor: "#FFFFFF",
		transition: theme.transitions.create(["width"], {
			duration: 100,
		}),
	},
	"& .MuiSwitch-track": {
		borderRadius: 15,
		opacity: 1,
		backgroundColor: theme.palette.mode === "dark" ? "rgba(255,255,255,.35)" : "rgba(0,0,0,.25)",
		boxSizing: "border-box",
	},
}));

export default AntSwitch;
