import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import map from "lodash/fp/map";

import { ListItem, ListItemSecondaryAction, ListItemText, Tooltip as MUITooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

import tableOfContentsStyle from "assets/jss/components/tableOfContentsStyle";
import { Paperclip, Locked } from "components/Icons";
import telemetryAddEvent from "utils/telemetryAddEvent";
import { StaffUserContext } from "../PdfViewerNew/PdfWithSideBar";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import useAttachmentsHighlight from "../PdfViewerNew/utils/useAttachmentsHighlight";
import { NewPaperClip, Policy } from "../Icons";
import { getAttachmentLinkText } from "utils/meetingElement";
import Icon from "atlas/components/Icon/Icon";

const useTableOfContentsStyles = makeStyles(tableOfContentsStyle);
const useStyles = makeStyles(() => ({
	policyIcon: {
		width: "20.48px",
		height: "22.58px",
	},
	paperClipIcon: {
		width: "20.48px",
		height: "22.58px",
	},
	attachmentsText: {
		marginLeft: "15px",
		marginRight: "12px",
	},
	lockIconStyling: {
		width: "20.48px",
		height: "22.58px",
	},
	lockIconStylingCopyMove: {
		width: "28.48px",
		height: "22.58px",
		marginRight: "75px",
	},
}));

const TOCAttachments = (props) => {
	const {
		attachments,
		item,
		itemGuid,
		isMemberOnlySection,
		isConsentSection,
		isPublicCommentSection,
		toggleTableOfContent = () => {},
		isSmallDevice,
		setActive,
		telemetryPage,
		isPdfSidebar,
		setDataForAttachmentDocument,
		clickedOnAttachmentInSidePanel,
		selectedId,
		isAgendaItem,
		isRecommendationLevel,
		openGoalsPopup,
		isLastChildItem = false,
		canDrag = false,
		renderControl,
	} = props;
	const [clickedOnAttachment, setClickedOnAttachment] = useAttachmentsHighlight(false);
	const { t } = useTranslation("meetings");
	const dispatch = useDispatch();
	const isStaffUser = useContext(StaffUserContext);
	const tableOfContentsClasses = useTableOfContentsStyles({ lineLimit: 2 });

	const classes = useStyles();
	const showAttachments = (isMemberOnlySection, fileClosed) => {
		if (isPdfSidebar) {
			if ((isStaffUser && fileClosed && !isMemberOnlySection) || (isStaffUser && isMemberOnlySection)) {
				return false;
			}
			return true;
		}
		return true;
	};

	return (attachments || []).map((file, index) => {
		if (typeof file.linkText === "undefined") {
			file.linkText = getAttachmentLinkText(item, file.guid);
		}

		let attachmentText = file.name || file.description;
		if (file.linkText && file.linkText !== attachmentText) {
			attachmentText = `${file.linkText} (${attachmentText})`;
		}
		if (attachmentText.includes("&amp;")) {
			attachmentText = attachmentText.replaceAll("&amp;", "&");
		}

		return (
			!file.removed &&
			showAttachments(isMemberOnlySection, file.closed) && (
				<ListItem
					key={`outline-attachment-${file.guid}`}
					id={`outline-attachment-${file.guid}`}
					button
					role=""
					component="li"
					disableGutters
					divider
					style={{ paddingRight: "0px" }}
					classes={{
						root: clsx(
							"candrag-outline-item-secondary-attachment",
							file.copied && renderControl?.isAnimationAdded(document.getElementById(`outline-attachment-${file.guid}`))
								? "transition-animation"
								: file.copied && renderControl
									? "transition-animation-added"
									: "",
							{
								"member-only-section": isMemberOnlySection,
								"consent-section-outline": isConsentSection,
								"public-comment-section-outline-pdfsidebar": isPublicCommentSection && isPdfSidebar,
								"public-comment-section-outline": isPublicCommentSection && !isPdfSidebar,
								"member-only-section-last-child-item":
									isLastChildItem && index === attachments.length - 1 && isMemberOnlySection && canDrag,
								"consent-section-last-child-item": isLastChildItem && index === attachments.length - 1 && isConsentSection && canDrag,
								"public-comment-section-last-child-item":
									isLastChildItem && index === attachments.length - 1 && isPublicCommentSection && canDrag,
								pdfSideBar: isPdfSidebar,
								hyperlinkAttachment: isPdfSidebar && selectedId && selectedId.includes(file.guid) && clickedOnAttachment,
								[tableOfContentsClasses.backgroundColorForSelected]:
									renderControl && renderControl.highlightCheck && renderControl.highlightCheck(file),
								[tableOfContentsClasses.heightOfAttachmentAndRecomm]: renderControl,
							},
						),
					}}
					onClick={
						isPdfSidebar
							? (e) => {
									if (file.itemGuid) {
										setDataForAttachmentDocument(attachments, itemGuid, file);
									} else {
										openGoalsPopup(file);
									}
									clickedOnAttachmentInSidePanel();
									setClickedOnAttachment(true);
									dispatch(setActive(file.guid, true));
								}
							: () => {
									telemetryAddEvent(`${telemetryPage} - Outline navigation`);
									dispatch(setActive(itemGuid, true));
									if (isSmallDevice) {
										toggleTableOfContent();
									}
								}
					}
					data-cy={`outline-attachment-${file.guid}`}
					tabIndex={-1}
					onKeyDown={(e) => renderControl && renderControl.keyDownAction(e)}
				>
					<div
						className={clsx("pdfSideBarAttachmentContainer", {
							indentaionAgendaItem: isAgendaItem,
							indentaionRecommendationItem: isRecommendationLevel,
						})}
					>
						{file.itemGuid ? (
							file?.isPolicy ? ( //Check if a attachment is a policy
								<Policy className={classes.policyIcon} />
							) : (
								<NewPaperClip className={classes.paperClipIcon} />
							)
						) : (
							<div>{file.closed ? <Icon name={"goal-name-locked"} /> : <Icon name={"goal-name"} />}</div>
						)}
						<Tooltip title={attachmentText} placement="top">
							<ListItemText
								primaryTypographyProps={{
									variant: "h5",
									style: {
										fontWeight: 700,
										color: "#385F99",
									},
								}}
								primary={attachmentText}
								className={clsx({
									[tableOfContentsClasses.limitLines]: true,
									pdfSideBarAttachmentsText: isPdfSidebar,
									[classes.attachmentsText]: !isPdfSidebar,
								})}
							/>
						</Tooltip>
						{file.itemGuid && (isMemberOnlySection || (!isMemberOnlySection && file.closed)) && (
							<Locked className={!canDrag && renderControl ? classes.lockIconStylingCopyMove : classes.lockIconStyling} />
						)}
					</div>
				</ListItem>
			)
		);
	});
};
export default TOCAttachments;
