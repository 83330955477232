/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import clsx from "clsx";

import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import Icon from "atlas/components/Icon/Icon";

import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { STATUS_DEFAULT, STATUS_GOOD, STATUS_WARNING, STATUS_ADDEDTOAGENDA } from "atlas/assets/jss/utils/statusIndicators";
import { blackColor, whiteColor, grayColor, successColor, warningColor, primaryColor, internalColor } from "atlas/assets/jss/shared";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import { useWidthUp } from "atlas/utils/useWidth";
import { focusColor } from "atlas/assets/jss/shared";

const useStyles = makeStyles({
	statusLine: {},
	published: {
		cursor: "pointer",
	},
	label: {
		...typographyStyle.smallText,
	},
	statusCell: {
		textAlign: "left",
	},
	status: {
		...typographyStyle.status,
		whiteSpace: "nowrap",
		borderRadius: "4px",
		padding: "4px 8px 4px 8px",
		height: "24px",
		verticalAlign: "middle",
		textAlign: "center",
		display: "inline-block",
		boxSizing: "border-box",
		[`&.${STATUS_DEFAULT}`]: {
			backgroundColor: grayColor[0],
			color: whiteColor,
		},
		[`&.${STATUS_GOOD}`]: {
			backgroundColor: successColor,
			color: whiteColor,
		},
		[`&.${STATUS_WARNING}`]: {
			backgroundColor: warningColor,
			color: blackColor,
		},
		[`&.${STATUS_ADDEDTOAGENDA}`]: {
			backgroundColor: primaryColor[1],
			color: whiteColor,
		},
	},
	align: {
		display: "flex",
		alignItems: "center",
	},
	bottomTableMargin: {
		borderBottom: "8px solid transparent",
	},
	tabFocus: {
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
			padding: "0px 8px 0px 0px",
		},
	},
});

const StatusLine = (props) => {
	const {
		label,
		statusColor,
		statusText,
		tooltip,
		sharedOrPublished,
		openMethod,
		meetingId,
		agenda = true,
		draftReview,
		boardStatus,
		isCopyMove,
	} = props;
	const widthUpSm = useWidthUp("sm");
	const { statusLine, published, label: labelClass, statusCell, status, align, bottomTableMargin, tabFocus } = useStyles();

	const handleKeyPress = (e) => {
		if (e.key === "Enter" && sharedOrPublished) {
			openMethod(meetingId, agenda);
		}
	};

	const getDraftReviewStatusLine = () => {
		return (
			<>
				<div className={clsx(statusCell, "table-cell")}>
					<span className={clsx(status, draftReview.statusColor)} data-cy="status">
						{draftReview.status}
					</span>
				</div>
				(
				<div
					className={clsx("table-cell", tabFocus, {
						[published]: sharedOrPublished,
					})}
					role="link"
					aria-label="Opens in new window"
					tabIndex={0}
					onClick={() => openMethod(meetingId, true)}
					onKeyPress={handleKeyPress}
				>
					<Box display="flex" alignItems="center" height="100%" style={{ cursor: "pointer" }}>
						<Icon name="external-link" size="18px" color={grayColor[1]} data-cy="externalLink" />
					</Box>
				</div>
				)
			</>
		);
	};

	return (
		<>
			<div className={clsx(statusLine, "table-row")} data-cy={props["data-cy"]} data-published={sharedOrPublished || null}>
				<div className={clsx(labelClass, "table-cell")}>
					<Box display="flex" alignItems="center" height="100%">
						{label}
					</Box>
				</div>
				{draftReview && !sharedOrPublished && widthUpSm ? (
					getDraftReviewStatusLine()
				) : (
					<>
						<div className={clsx(statusCell, "table-cell")}>
							<Tooltip title={tooltip}>
								<span className={clsx(status, statusColor)} data-cy="status">
									{statusText}
								</span>
							</Tooltip>
						</div>
						{!isCopyMove && (
							<div
								className={clsx("table-cell", tabFocus, {
									[published]: sharedOrPublished || boardStatus == 11,
								})}
								role={sharedOrPublished || boardStatus == 11 ? "link" : null}
								aria-label={sharedOrPublished || boardStatus == 11 ? "Opens in new window" : null}
								tabIndex={sharedOrPublished || boardStatus == 11 ? 0 : null}
								onClick={sharedOrPublished || boardStatus == 11 ? () => openMethod(meetingId) : null}
								onKeyPress={handleKeyPress}
							>
								<Box display="flex" alignItems="center" height="100%">
									{(sharedOrPublished || boardStatus == 11) && (
										<Icon name="external-link" size="18px" color={grayColor[1]} data-cy="externalLink" />
									)}
								</Box>
							</div>
						)}
					</>
				)}
			</div>
			{draftReview && !sharedOrPublished && !widthUpSm && (
				<div className={clsx(statusLine, bottomTableMargin, "table-row")}>
					<div className={clsx(statusCell, "table-cell")}></div>
					{getDraftReviewStatusLine()}
				</div>
			)}
		</>
	);
};

export default StatusLine;
