import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Badge from '@mui/material/Badge';

import Icon from '../Icon/Icon';

import menuStyle from '../../assets/jss/components/menuStyle';
import { grayColor } from '../../assets/jss/shared';

const useStyles = makeStyles(menuStyle);

const NonModalMenu = (props) => {
	const classes = useStyles({});

	const { id, className, open, anchorEl, position, onClose, children, options, paramObject, showCount = false } = props;

	const handleListKeyDown = (event) => {
		if (event.key === 'Tab') {
			// Return the focus to the anchor element
			if (anchorEl && typeof anchorEl.focus === 'function') {
				anchorEl.focus();
			}

			onClose();
		}
	};

	const getMenuItemContent = (option) => {
		return (
			<>
				{option.icon && (
					<Box mr={1} display="flex">
						<Icon name={option.icon} size="24" />
					</Box>
				)}
				{showCount ? (
					<div className={classes.badgeContainer} tabIndex={0} ariaLabel={option.count}>
						<Badge className={classes.badgeContent} color="error" badgeContent={option.count}>
							{option.label}
						</Badge>
						{option.isPmNewPolicy && (
							<span
								className={clsx('policies-status', 'margin-left-10', { 'policies-status-margin': option.count }, 'new')}
								data-cy="status"
								aria-label={'New'}
								tabIndex={0}
							>
								New
							</span>
						)}
					</div>
				) : (
					option.label
				)}
				{option.external && (
					<Box ml={1} display="flex">
						<Icon name="external-link" size="16px" color={grayColor[0]} />
					</Box>
				)}
			</>
		);
	};

	const getMenuItem = (option) => {
		return (
			<MenuItem
				id={option.id}
				key={option.key || `summaryMenuOption-${option.label}`}
				aria-label={option.ariaLabel || option['aria-label'] || option.label}
				aria-haspopup={option.ariaHasPopup || option['aria-haspopup'] || null}
				type={option.type || option['type'] || null}
				aria-expanded={option.ariaExpanded || option['aria-expanded'] || null}
				onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();
					option.actionFunction(e, paramObject);
					onClose();
				}}
				data-cy={option.dataCy || option['data-cy'] || `menu-${id}-${option.label.replace(/ /g, '')}`}
				data-separator={option.separator ? true : null}
			>
				{option.navigateUrl ? (
					<a href={option.navigateUrl} target={option.external ? '_blank' : undefined}>
						{getMenuItemContent(option)}
					</a>
				) : (
					getMenuItemContent(option)
				)}
			</MenuItem>
		);
	};

	return (
		<Popper
			id={id}
			className={clsx(classes.default, className)}
			open={open}
			anchorEl={anchorEl}
			role="presentation"
			transition
			placement={position}
			modifiers={[
				{
					name: 'preventOverflow',
					enabled: true,
					options: {
						boundariesElement: 'viewport',
					},
				},
			]}
		>
			{({ TransitionProps, placement }) => (
				<Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom-start' ? 'center top' : 'center bottom' }}>
					<Paper elevation={8} role="document" square>
						<ClickAwayListener onClickAway={onClose}>
							<MenuList autoFocusItem={open} onKeyDown={handleListKeyDown} className={classes.menu}>
								{options && options.length > 0 ? options.filter((option) => !option.hidden).map(getMenuItem) : children}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	);
};

NonModalMenu.propTypes = {
	id: PropTypes.string.isRequired,
	className: PropTypes.string,
	open: PropTypes.bool,
	anchorEl: PropTypes.object.isRequired,
	position: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

NonModalMenu.defaultProps = {
	className: null,
	open: false,
	position: 'bottom-start',
	children: undefined,
};

export default NonModalMenu;
