import React, { useState, useEffect } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { Tab, Tabs } from "@mui/material";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import { infoColor, focusColor } from "atlas/assets/jss/shared";
import { useSignInDialog } from "utils/isSignedIn";
import { formatDate } from "utils/date";
import SelectInput from "atlas/components/FormControls/SelectInput";
import SearchInput from "components/Search/SearchInput";
import { useUpdateObject } from "utils/updateObject";
import { useSelector, useDispatch } from "react-redux";
import { useSelectInputOptionStyles, getNoOptionsMenuItem, getDropdownProgressIndicator, getMenuItem } from "utils/dropDown";
import Container from "@mui/material/Container";
import ListCard from "atlas/components/Cards/ListCard";
import List from "@mui/material/List";
import entityListStyle from "assets/jss/components/entityListStyle";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import CreateMeeting from "views/Meetings/CreateMeeting";
import { useWidthUp, useWidthDown } from "atlas/utils/useWidth";
import MeetingPublishStatus from "components/MeetingCard/MeetingPublishStatus";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
	setMeetingDetails,
	setWarningMessage,
	clearWarningMessage,
	resetState,
	fetchAgendaItems,
	searchMeetings,
	clearSelectedMeeting,
} from "redux/NewCopyAndMove/actions";
import { getMeetingTemplates } from "redux/meetingTemplate/actions";
import NoMeetingsPage from "./NoMeetingsPage";
import { getSharedOrPublished } from "../utils/functions";
import Warning from "../utilsComponent/Warning";
import { useTranslation } from "react-i18next";
import ellipsedTruncateText from "utils/ellipsedTruncateText";
import { getFieldValue } from "atlas/utils/itemData";
import { stripHtml } from "utils/processHtml";
import { SET_LEFT_MEETING_AGENDA } from "redux/NewCopyAndMove/types";
import { assignBriefingNotesIcon } from "../utils/copyMovefunctions";
import { useNavigate } from "react-router-dom";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";

const useStyles = makeStyles({
	headerContainer: {
		display: "flex",
		backgroundColor: "#2f3b4d",
		height: "56px",
		justifyContent: "space-between",
	},
	headerTitle: {
		color: "#FFFFFF",
		fontSize: "16px",
		fontWeight: "400",
		display: "flex",
		alignItems: "center",
	},
	headerItems: {
		display: "flex",
		alignItems: "center",
	},
	loadingIndicatorContainer: {
		position: "absolute",
		top: 0,
		width: "100%",
		height: "100%",
		backgroundColor: "white",
		zIndex: 1,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	mainContent: {
		maxWidth: (props) => (props.widthUpLg ? "70%" : props.widthUpMd ? "70%" : undefined),
		minWidth: (props) => (props.widthUpLg ? "70%" : props.widthUpMd ? "70%" : undefined),
	},
	textDetails: {
		marginBottom: (props) => (!props.widthUpMd ? "16px" : undefined),
	},
	containerStyle: {
		backgroundColor: "#f5f8f9",
	},
	copyMoveMainDiv: {
		paddingTop: "56px",
		margin: "auto",
		height: "75vh",
		maxWidth: "768px",
		backgroundColor: "white",
	},
	meetingsList: {
		overflowY: "auto",
		height: "calc(100vh - 325px)",
	},
	meetingListWithWarning: {
		overflowY: "auto",
		height: "calc(100vh - 415px)",
	},

	meetingsTab: {
		display: "flex",
		gap: "40px",
	},
	meetingTabText: {
		fontWeight: "600",
		fontSize: "16px",
		color: "#455D82",
		paddingBottom: "6px",
	},
	meetingTabUnderline: {
		backgroundColor: "#252C44",
		height: "3px",
		maxWidth: "144px",
		width: "100%",
	},
	meetingTabTextSelected: {
		color: "#252C44",
	},
	searchInput: {
		"&.MuiFormControl-root": {
			marginTop: "0px",
			marginBottom: "0px",
		},

		maxWidth: "328px",
		width: "100%",
		height: "40px",
	},
	tabs: {
		"& .MuiTab-root": {
			...typographyStyle.tab,
			color: infoColor,
			minWidth: "auto",
			minHeight: "auto",
			height: "40px",
			padding: "8px 0",
			margin: "0 16px",
			"&.Mui-selected": {
				...typographyStyle.tab,
				color: "#252C44",
			},
		},
		"& .MuiTabs-indicator": {
			backgroundColor: "#252C44",
		},
	},
	calendarIcon: {
		display: "inline-flex",
		alignItems: "center",
		flexWrap: "wrap",
		width: "48px",
		height: "50px",
		marginRight: "10px",
		backgroundColor: "#E6E6E6",
		borderRadius: "10px",
		overflow: "hidden",
		"& *": {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			width: "100%",
			fontStyle: "normal",
		},
		"& h5": {
			height: "24px",
			fontSize: "12px",
			color: "#FFFFFF",
			textTransform: "uppercase",
			backgroundColor: "#455D82",
		},
		"& h4": {
			letterSpacing: "-0.05em",
			fontSize: "16px",
			height: "24px",
			fontWeight: "600",
			color: "#2e2e2e",
		},
	},
	meetingStatusTag: {
		display: "flex",
		justifyContent: "flex-end",
	},
	tabFocusStyling: {
		"&:focus-visible": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "-2px",
			borderRadius: "4px",
			padding: "0px 5px 0px 5px",
		},
	},
	newMeetingTab: {
		overflowY: "auto",
		height: "calc(100vh - 280px)",
	},
	newMeetingTabWithWarning: {
		overflowY: "auto",
		height: "calc(100vh - 330px)",
	},
});
const useEntityListStyles = makeStyles(entityListStyle);
const defaultMeetingType = { meetingType: 0 };
const defaultMeeting = { meeting: 0 };

const MeetingsTab = (props) => {
	const { afterMeetingCreated, copyOrMove, parentMeetingId, checkCreateMeetingTab, isCopy, setLayoutControlContinue, setThisReference } =
		props;
	const { t } = useTranslation("meetings");
	const showSignIn = useSignInDialog();
	const [meetingTypes, setMeetingTypes] = useState(null);
	const [selectWidth, setSelectWidth] = useState(0);
	const [page, setPage] = useState(1);
	const [moreMeetings, setMoreMeetings] = useState(false);
	const [handleCreateMeetingMethod, setHandleCreateMeetingMethod] = useState(null);
	const [meetingId, setMeetingId] = useState(null);
	const [loading, setLoading] = useState(false);
	const widthUpMd = useWidthUp("md");
	const widthUpLg = useWidthUp("lg");
	const widthDownMd = useWidthDown("md");
	const classes = useStyles({ widthUpMd, widthUpLg });
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { selectedMeetingId, toAgendaWarning, originalLeftMeetingAgendaItems, creatingMeeting } = useSelector(
		(state) => state.newCopyAndMoveReducer,
	);
	const [selections, setSelections] = useState({
		...defaultMeetingType,
		...defaultMeeting,
	});
	const [meetings, setMeetings] = useState([]);
	const [filterValues, setFilterValues] = useState({
		searchKeyword: "",
		meetingType: 0,
	});
	const [tab, setTab] = useState(0);
	const selectInputOptionClasses = useSelectInputOptionStyles({ selectWidth });
	const updateSelections = useUpdateObject(setSelections);
	const listClasses = useEntityListStyles();
	const tabOptions = ["Upcoming meetings", "Create new meeting"];

	const handleChange = (e) => {
		const { value } = e.target;
		setFilterValues({ ...filterValues, searchKeyword: value });
	};
	const moreClick = () => {
		loadUpcomingMeetings(true);
	};
	const createNewMeetingClick = () => {
		setTab(1);
		checkCreateMeetingTab(true);
	};
	const onClearSearchClick = () => {
		setFilterValues({ ...filterValues, searchKeyword: "" });
	};

	const loadUpcomingMeetings = (loadMore = false) => {
		const queryFilters = {};
		const pageSize = 15;
		const currentDateTime = new Date();
		const date = new Date(
			currentDateTime.getFullYear(),
			currentDateTime.getMonth(),
			currentDateTime.getDate(),
			currentDateTime.getHours(),
			currentDateTime.getMinutes(),
		);
		const textDateTime = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()} ${date.getHours()}:${date.getMinutes()}`;
		queryFilters.meetingName = filterValues.searchKeyword || "";
		queryFilters.from = textDateTime;
		queryFilters.rowFrom = (loadMore ? page * pageSize : 0) + 1;
		queryFilters.rowTo = queryFilters.rowFrom + pageSize - 1;
		queryFilters.meetingTypes = selections.meetingType || [];
		queryFilters.ascending = tab === 0;
		setLoading(true);

		dispatch(searchMeetings(queryFilters))
			.then((res) => {
				setLoading(false);
				if (loadMore) {
					const meetingArray = res.body.meetings.filter((meeting) => !meetings.some((m) => m.id === meeting.id)); // Filter out duplicates
					const removeParentMeeting = meetingArray.filter((meeting) => String(meeting.id) !== parentMeetingId);
					setMeetings([...meetings, ...removeParentMeeting]);
					setPage(page + 1);
					setMoreMeetings(res.body.more);
				} else {
					const removeParentMeeting = res.body.meetings.filter((meeting) => String(meeting.id) !== parentMeetingId);
					setMeetings(removeParentMeeting);
					setMoreMeetings(res.body.more);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const loadMeetingTypes = () => {
		dispatch(getMeetingTemplates())
			.then((res) => {
				setMeetingTypes(res);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getWarningText = (boardStatus, publicStatus) => {
		const status = getSharedOrPublished(boardStatus, publicStatus);
		if (status === "shared") {
			return t(`app:${copyOrMove}.toShared`);
		} else if (status === "published") {
			return t(`app:${copyOrMove}.toPublished`);
		}
	};

	useEffect(() => {
		// Set the drop-down options width to match the width of the control
		const itemSelect = document.getElementById("meeting-types");
		if (itemSelect) {
			setSelectWidth(itemSelect.offsetWidth);
		}
	});
	useEffect(() => {
		//This useEffect is used to load the meeting types , and agenda items when the component is mounted
		loadMeetingTypes();
		if (Object.keys(originalLeftMeetingAgendaItems).length === 0) {
			dispatch(fetchAgendaItems(parentMeetingId))
				.then((response) => {
					if (response.items.length === 0) {
						let option = notifierMessage("Please add agenda items in a meeting to copy or move", "error");
						dispatch(setSnackbarOptions(option));
						navigate(`/meeting/detailsV2/${parentMeetingId}`);
					} else {
						const startTimeStamp = response?.meeting?.startTimeStamp;
						const meetingActive = startTimeStamp ? startTimeStamp <= Math.floor(Date.now() / 1000) : false;
						if (meetingActive && !isCopy) {
							let option = notifierMessage("This meeting does not have move access. Please try Copy", "error");
							dispatch(setSnackbarOptions(option));
							navigate(`/meeting/detailsV2/${parentMeetingId}`);
						}
						response.items.length > 0 && assignBriefingNotesIcon(response);
						dispatch({
							type: SET_LEFT_MEETING_AGENDA,
							payload: response,
						});
					}
				})
				.catch((error) => {
					if (error.status === 403 && error.response.body.Message === "Access Denied") {
						let option = notifierMessage(error.response.body.Message, "error");
						dispatch(setSnackbarOptions(option));
						navigate(`/`);
					} else {
						let option = notifierMessage("Something went wrong.Please Try again", "error");
						dispatch(setSnackbarOptions(option));
						setLoading(false);
						navigate(`/`);
					}
				});
		}
	}, []);

	useEffect(() => {
		// This useEffect is used to load the meetings based on the search keyword and meeting type
		const getData = setTimeout(() => {
			loadUpcomingMeetings();
		}, 500);
		return () => clearTimeout(getData);
	}, [filterValues.searchKeyword]);

	useEffect(() => {
		//This useEffect is used to reset the page value when there is no filter applied in meeting list
		if (filterValues.searchKeyword === "" && selections.meetingType === 0) {
			setPage(1);
		}
	}, [filterValues.searchKeyword, selections.meetingType]);

	useEffect(() => {
		// This useEffect is used to load the meetings based on the search keyword and meeting type
		loadUpcomingMeetings();
	}, [selections.meetingType]);

	useEffect(() => {
		if (handleCreateMeetingMethod) {
			setLayoutControlContinue(() => handleCreateMeetingMethod);
		}
	}, [handleCreateMeetingMethod]);

	useEffect(() => {
		if (tab === 1) {
			setMeetingId(null);
			dispatch(clearSelectedMeeting());
			dispatch(clearWarningMessage());
		}
	}, [tab]);

	const onTabChange = (_e, newTab) => {
		if (newTab === 1) {
			checkCreateMeetingTab(true);
		} else {
			checkCreateMeetingTab(false);
		}
		setTab(newTab);
	};

	const getMeetingTypes = () => {
		const meetingTypeMenuItems = [];

		if (meetingTypes) {
			meetingTypeMenuItems.push(getNoOptionsMenuItem(t));

			meetingTypes
				.filter((meetingType) => !meetingType.deleted)
				.forEach((meetingType) => {
					meetingTypeMenuItems.push(
						getMenuItem(meetingType.id, meetingType.name, `meeting-type-${meetingType.id}`, selectInputOptionClasses),
					);
				});
		} else {
			meetingTypeMenuItems.push(getDropdownProgressIndicator());
		}

		return meetingTypeMenuItems;
	};
	const handleRadioButtonChange = (event, focusAction = false) => {
		setMeetingId(event.target.value);
		let selectedItem = null;
		if (focusAction) {
			const labelElement = event.currentTarget.querySelector("label");
			selectedItem = JSON.parse(labelElement.getAttribute("data-item"));
		} else {
			selectedItem = JSON.parse(event.target.closest("label").getAttribute("data-item"));
		}
		if (selectedMeetingId !== selectedItem) {
			dispatch(resetState());
			dispatch(setMeetingDetails(selectedItem));
		}

		if (getSharedOrPublished(selectedItem.boardAgendaStatus, selectedItem.publicAgendaStatus)) {
			if (toAgendaWarning.length > 0) {
				dispatch(clearWarningMessage());
			}
			const warningMessage = getWarningText(selectedItem.boardAgendaStatus, selectedItem.publicAgendaStatus, copyOrMove, t);
			dispatch(setWarningMessage({ id: "generalWarning", message: [warningMessage] }));
		} else {
			dispatch(clearWarningMessage());
		}
	};

	const handleKeyDown = (e) => {
		switch (e.key) {
			case "ArrowUp":
				const previousSibling = e.target.previousSibling;
				if (previousSibling) {
					const descendants = previousSibling.getElementsByTagName("li");
					if (descendants.length > 0) {
						// Focus on last descendant of previous sibling
						descendants[descendants.length - 1].focus();
					} else {
						// Focus on previous sibling
						previousSibling.focus();
					}
				} else {
					// Focus on the parent
					e.target.parentNode?.closest("li")?.focus();
				}
				break;

			case "ArrowDown":
				const descendants = e.target.getElementsByTagName("li");
				if (descendants.length > 0) {
					// Focus on first descendant
					descendants[0].focus();
				} else {
					// Find next sibling and focus on it if it exists
					let listItem = e.target;
					while (listItem && !listItem.nextSibling) {
						listItem = listItem.parentNode?.closest("li");
					}
					const nextSibling = listItem?.nextSibling;
					if (nextSibling) {
						nextSibling.focus();
					}
				}
				break;

			case "Enter":
				e.preventDefault();
				e.stopPropagation();

				if (handleRadioButtonChange) {
					handleRadioButtonChange(e, true);
				}
				break;
		}
	};

	const radioButton = (item) => {
		return (
			<FormControl sx={{ marginTop: "-8px" }}>
				<RadioGroup
					aria-labelledby="Radio button for meeting selection"
					name="Radio button for meeting selection"
					value={selectedMeetingId ? selectedMeetingId : meetingId}
					onChange={(e) => handleRadioButtonChange(e, false)}
				>
					<FormControlLabel value={item.id} data-item={JSON.stringify(item)} control={<Radio tabIndex={-1} />} />
				</RadioGroup>
			</FormControl>
		);
	};

	const setMethod = (method, thisReference) => {
		if (method) {
			setHandleCreateMeetingMethod(() => method);
			setThisReference(thisReference);
		}
	};

	const handleMeetingObject = {
		handleControlMeeting: setMethod,
		calledFromCopyMove: true,
		preSelectedMeetingType: selections.meetingType !== 0 ? selections.meetingType : null,
	};

	return (
		<div>
			{toAgendaWarning?.generalWarning && <Warning warnings={[...toAgendaWarning.generalWarning]} />}

			<div style={{ paddingTop: "20px" }}>
				<Tabs className={classes.tabs} aria-label={"Meetings-options"} value={tab} onChange={onTabChange}>
					{tabOptions.map((tabOptions) => {
						return <Tab label={tabOptions} className={classes.tabFocusStyling} disableRipple data-cy="tab-internal" tabIndex={0} />;
					})}
				</Tabs>
				{tab === 0 && (
					<div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", paddingTop: "30px", gap: "28px" }}>
						<div style={{ width: "100%" }}>
							<div style={{ fontWeight: "400", fontSize: "13px", paddingBottom: "5px" }}>{t("app:commonCopyMove.searchMeetingTitle")}</div>
							<SearchInput
								key="toolbar-search"
								size="small"
								fullWidth={true}
								containerFullWidth={true}
								value={filterValues.searchKeyword || ""}
								showClearIcon={filterValues.searchKeyword && filterValues.searchKeyword.length > 0}
								onClearClick={onClearSearchClick}
								onChange={handleChange}
								className={classes.searchInput}
								copyOrMove={copyOrMove}
							/>
						</div>
						{meetingTypes && meetingTypes.length > 1 && !widthDownMd && (
							<div style={{ width: "100%" }}>
								<div style={{ fontWeight: "400", fontSize: "13px", paddingBottom: "5px" }}>
									{t("app:commonCopyMove.selectMeetingTemplate")}
								</div>
								<SelectInput
									id="meeting-types"
									className={classes.searchInput}
									noDefaultClassName
									size="small"
									value={selections.meetingType}
									onChange={(e) => updateSelections(e, "meetingType", false, true)}
									data-cy="meeting-types"
								>
									{getMeetingTypes()}
								</SelectInput>
							</div>
						)}
					</div>
				)}

				{tab === 0 && (
					<Container maxWidth="xl" className="full-width">
						<Box
							className={clsx({
								[classes.meetingsList]: !toAgendaWarning.generalWarning,
								[classes.meetingListWithWarning]: toAgendaWarning.generalWarning,
							})}
						>
							{meetings && meetings.length > 0 ? (
								<List className={listClasses.list}>
									{meetings.map((meeting, index) => (
										<>
											<ListCard
												isCopyMove={true}
												item={meeting}
												disableLink={true}
												dateField="date"
												nameField="cleanName"
												subTitleField={(meeting) =>
													formatDate(null, meeting.startTime, meeting.endTime, t("app:at"), t("from"), t("to"), false)
												}
												reserveSubTitleSpace={widthUpMd}
												secondaryContent={
													<div className={classes.secondaryContent}>
														{getSharedOrPublished(meeting.boardAgendaStatus, meeting.publicAgendaStatus) !== null && (
															<div>
																<MeetingPublishStatus
																	meetingId={meeting.id}
																	boardStatus={meeting.administrator || meeting.member ? meeting.boardAgendaStatus : undefined}
																	publicStatus={meeting.publicAgendaStatus}
																	publicReleaseDay={meeting.publicAgendaReleaseDay}
																	boardHtmlId={meeting.boardAgendaHtmlId}
																	previousUrl="/meetings"
																	telemetryPage="All meetings"
																	width="auto"
																	isCopyMove={true}
																/>
															</div>
														)}
													</div>
												}
												classes={{
													mainContent: classes.mainContent,
													textDetails: classes.textDetails,
													calendarIconStyle: classes.calendarIcon,
													secondaryContent: classes.meetingStatusTag,
												}}
												ellipsedTruncatedText={ellipsedTruncateText(stripHtml(getFieldValue(meeting, "name")), 40, 50)}
												isEllipsed={true}
												handleRadioButtonChange={handleRadioButtonChange}
												radioButton={radioButton}
												tabIndex={selectedMeetingId && meeting.id === selectedMeetingId ? 0 : index === 0 ? 0 : -1}
												handleKeyDown={handleKeyDown}
											></ListCard>
										</>
									))}
									{moreMeetings && (
										<Grid key="load-more" container justifyContent="center">
											<Button
												variant="contained"
												color="primary"
												data-cy="loadMore"
												onClick={() => moreClick()}
												aria-label={t("meetings:buttons.loadMore")}
												className={clsx(classes.tabFocusStyling)}
											>
												{t("meetings:buttons.loadMore")}
											</Button>
										</Grid>
									)}
								</List>
							) : !loading && !meetings.length > 0 && filterValues.searchKeyword === "" ? (
								<NoMeetingsPage isSearchedByUser={false} createNewMeetingClick={createNewMeetingClick} />
							) : !loading && !meetings.length > 0 && filterValues.searchKeyword !== "" ? (
								<NoMeetingsPage
									isSearchedByUser={true}
									onClearSearchClick={onClearSearchClick}
									createNewMeetingClick={createNewMeetingClick}
								/>
							) : (
								<CircularProgressIndicator key="loading-more" data-cy="loadingMore" />
							)}
						</Box>
					</Container>
				)}
				{tab === 1 && (
					<Box
						className={clsx({
							[classes.newMeetingTab]: !toAgendaWarning.generalWarning,
							[classes.newMeetingTabWithWarning]: toAgendaWarning.generalWarning,
						})}
					>
						<CreateMeeting showSignIn={showSignIn} afterMeetingCreated={afterMeetingCreated} isCopyMove={handleMeetingObject} />
					</Box>
				)}
			</div>
		</div>
	);
};

export default MeetingsTab;
