export const SET_MEETING_DETAILS = "SET_MEETING_DETAILS";
export const SET_TO_AGENDA_WARNING_MESSAGE = "SET_TO_AGENDA_WARNING_MESSAGE";
export const CLEAR_WARNING_MESSAGE = "CLEAR_WARNING_MESSAGE";
export const SET_OVERALL_SELECTED_MEETING_ITEMS = "SET_OVERALL_SELECTED_MEETING_ITEMS";
export const SET_SMART_SELECT_TOGGLE = "SET_SMART_SELECT_TOGGLE";
export const SET_SMART_SELECTED_ITEMS = "SET_SMART_SELECTED_ITEMS";
export const RESET_STATE = "RESET_STATE";
export const SET_LEFT_AGENDA_OBJECT = "SET_LEFT_AGENDA_OBJECT";
export const SET_RIGHT_AGENDA_MAP = "SET_RIGHT_AGENDA_MAP";
export const SET_LEFT_MEETING_AGENDA = "SET_LEFT_MEETING_AGENDA";
export const SET_RIGHT_MEETING_AGENDA = "SET_RIGHT_MEETING_AGENDA";
export const SET_FINAL_RIGHT_MEETING_AGENDA = "SET_FINAL_RIGHT_MEETING_AGENDA";
export const SET_SOURCE_MEETING_DETAILS = "SET_SOURCE_MEETING_DETAILS";
export const SET_NEW_MEETING_TAG = "SET_NEW_MEETING_TAG";
export const RESET_LEFT_AGENDA_OBJECT = "RESET_LEFT_AGENDA_OBJECT";
export const CREATE_ITEMS_FOR_OVERFLOW = "CREATE_ITEMS_FOR_OVERFLOW";
export const SET_FIRST_DELETE_BUTTON_INDEX = "SET_FIRST_DELETE_BUTTON_INDEX";
export const CLEAR_SELECTED_MEETING = "CLEAR_SELECTED_MEETING";
export const CREATE_MEETING_IN_PROGRESS = "CREATE_MEETING_IN_PROGRESS";
