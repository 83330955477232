export const isSingleLine = (element) => {
	if (!element) return false;
	const style = window.getComputedStyle(element); // Get the computed styles
	const lineHeight = parseFloat(style.lineHeight); // Get the line-height
	const elementHeight = element.clientHeight; // Get the height of the element
	const lineCount = Math.round(elementHeight / lineHeight); // Calculate the number of lines

	return lineCount === 1;
};

export const getElement = (id) => {
	return document.getElementById(id)?.getElementsByTagName("p")[0];
};

export const handleKeyDownDND = (event) => {
	if (event.key === "Enter" || event.key === "Space") {
		// Prevent drag from being initiated via Enter key
		event.preventDefault();
		event.stopPropagation();
		event.target.parentElement?.click();
	}
};

export const handleKeyDown = (e) => {
	switch (e.key) {
		case "Tab":
			let listItemTab = e.target.getElementsByTagName("div");
			while (listItemTab && !listItemTab.length === 0) {
				listItemTab = listItemTab.parentNode?.closest("span");
			}
			const nextSiblingTab = e.target.querySelector("#moveButtons");
			if (nextSiblingTab) {
				nextSiblingTab.firstChild?.focus();
				e.preventDefault();
				e.stopPropagation();
			}
			break;

		case "ArrowLeft":
			let listItemLeft = e.target;
			if (listItemLeft) {
				listItemLeft.previousSibling?.focus();
				e.preventDefault();
				e.stopPropagation();
			}
			break;

		case "ArrowRight":
			let listItemRight = e.target;
			if (listItemRight) {
				listItemRight.nextSibling?.focus();
				e.preventDefault();
				e.stopPropagation();
			}
			break;

		case "ArrowUp":
			let listItemUp = e.target.parentElement;
			while (listItemUp && !listItemUp.previousSibling) {
				listItemUp = listItemUp.parentNode?.closest("span");
			}
			let previousSibling = listItemUp?.previousSibling;
			if (previousSibling) {
				previousSibling.firstChild?.focus();
			}
			break;

		case "ArrowDown":
			let listItemDown = e.target.parentElement;
			while (listItemDown && !listItemDown.nextSibling) {
				listItemDown = listItemDown.parentNode?.closest("span");
			}
			let nextSibling = listItemDown?.nextSibling;
			if (nextSibling) {
				nextSibling.firstChild?.focus();
			}
			break;

		case "Space":
			e.preventDefault();
			e.stopPropagation();
			e.target.getElementsByTagName("li")?.[0]?.click();
			break;
	}
};
