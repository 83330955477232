import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import clsx from "clsx";
import { API_HOST } from "config/env";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Button, ButtonGroup, IconButton, ListItem, ListItemSecondaryAction, Typography, Link, MenuItem } from "@mui/material";
import NonModalMenu from "atlas/components/Menu/NonModalMenu";
import Icon from "atlas/components/Icon/Icon";
import { primaryColor, whiteColor, blackColor } from "atlas/assets/jss/shared";
import telemetryAddEvent from "utils/telemetryAddEvent";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import LiveMeetingRadioButton from "views/LiveMeeting/components/LiveMeetingRadioButton";
import LiveMeetingAttachments from "views/LiveMeeting/components/LiveMeetingAttachments";
import TimeStampItem from "views/LiveMeeting/components/TimeStampItem";
import minutesStyle from "assets/jss/components/minutesStyle";
import Motion from "./Motion";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import { displayErrorNotification, displaySuccessNotification } from "views/Policy/utils/getNotification";
import { publishPolicyAttachments } from "redux/policyGovernance/actions";
import { getVotingResults } from "views/LiveMeeting/utils/votingUtils";
import { formatDateForPolicy } from "views/Policy/utils/getFormattedDate";
import LiveMeetingPolicyPublishDialog from "./LiveMeetingPolicyPublishDialog";
import Draggable from "atlas/components/DragAndDrop/Draggable";
import DragHandle from "atlas/components/DragAndDrop/DragHandle";
import { useDroppable } from "@dnd-kit/core";
import Parser from "html-react-parser";
import dragAndDropStyle from "atlas/assets/jss/components/dragAndDropStyle";
import AccessibleIconButton from "atlas/components/Buttons/AccessibleIconButton";
import MenuItemWithTooltip from "atlas/components/Menu/MenuItemWithTooltip";

const useStyle = makeStyles(minutesStyle);
const useDragAndDropStyles = makeStyles(dragAndDropStyle);

const LiveMeetingItem = (props) => {
	const {
		item,
		isClosedMeeting,
		isMemberOnlyHeading,
		isConsentHeading,
		isPublicCommentHeading,
		isHeading,
		isItem,
		isResolution,
		isSubHeading,
		addBottomBorder,
		videoExists,
		meeting,
		rollCall,
		otherRollCallTypes,
		additionalUsers,
		minutesItems,
		selected,
		presenting,
		active,
		votingData,
		onlineVoters,
		votingSettings,
		adoptUpdating,
		policyData = [],
		handleSelect,
		elementsRef,
		elementsIndex,
		removeText,
		addText,
		editorFieldTextDeleted,
		forceUpdate,
		showSignIn,
		handleUpdateMotion,
		handleAddMotion,
		adoptPublishPreviousMinutes,
		isPolicyMotion,
		signalRClient,
		canDrag,
		canDrop,
		readOnly = false,
		dragPlaceholder,
		handleMenu,
		menuAnchor,
		addItemMenuOptions: getAddItemMenuOptions,
		afterElementRefSet,
		showMenu,
		showMenuOptions = true,
		menuOptions: getMenuOptions,
	} = props;
	const { t } = useTranslation("agendaMenu");
	const { fields, attachments } = item;
	const classes = useStyle({ isSubHeading, addTopBorder: !isMemberOnlyHeading && !isConsentHeading });
	const dragAndDropClasses = useDragAndDropStyles();
	const [openMenu, setOpenMenu] = useState(false);
	const addMenuOpen = Boolean(menuAnchor?.anchor && !menuAnchor.overflow && menuAnchor.guid === item.guid);
	const overflowMenuOpen = Boolean(menuAnchor?.anchor && menuAnchor.overflow && menuAnchor.guid === item.guid);
	const [selectedMenuOption, setSelectedMenuOption] = useState({});
	const [showVotingStatus, setShowVotingStatus] = useState(false);
	const [dialogs, setDialogs] = useState({});
	const [anchor, setAnchor] = useState(null);
	const dragProps = canDrag ? { dragId: item.guid, dragComponent: "div" } : {};
	const { setNodeRef } = canDrop
		? useDroppable({
				id: item.guid,
			})
		: {};
	const { policyEnabled } = useContext(SettingsContext);
	const dispatch = useDispatch();

	const menuOptions = showMenuOptions
		? typeof getMenuOptions === "function"
			? getMenuOptions({
					item,
					isHeading,
					isSubHeading,
					isMemberOnlyHeading,
					isConsentHeading,
					isPublicCommentHeading,
					showVotingStatus,
					isResolution,
				})
			: getMenuOptions
		: [];

	const addItemMenuOptions = typeof getAddItemMenuOptions === "function" ? getAddItemMenuOptions(item) : getAddItemMenuOptions;

	//checkbox
	const isSelected = selected === item.guid;

	const addBottomBorderToThis = addBottomBorder && item.attachments.length === 0;

	let showAdoptPublishPreviousMinutes = null;
	let minutesToAdoptMeetingId = null;
	if (item && isItem && item.fields && item.fields.Consent && !item.fields.Consent.Value && item.itemType == 4) {
		showAdoptPublishPreviousMinutes = item.itemToAdoptPreviousMinutes;
		minutesToAdoptMeetingId = item.minutesToAdoptMeetingId;
	}
	let votingPassed = false;
	if (isResolution) {
		const votingResults = getVotingResults(item, rollCall, meeting);
		if (votingResults) {
			votingPassed = votingResults.votePassed;
		}
	}

	const publishPolicyMenuOptions = [
		{
			id: "publish-rescind",
			label: t("policyrescind"),
			ariaLabel: t("policyrescind"),
			actionFunction: (e) => {
				handleToggleMenuForPolicy(e, "rescind");
			},
			"data-cy": "publish-rescind",
		},
	];

	const handleToggleMenuForPolicy = (e, type) => {
		e.stopPropagation();
		e.preventDefault();
		let obj = {
			adoptedDate: formatDateForPolicy(meeting?.date),
			policyGuids: policyData.map((policy) => policy.sourcePolicyGuid),
		};
		if (type === "publish") {
			setDialogs({ publishPolicy: { data: obj, policies: policyData } });
		} else {
			telemetryAddEvent(`Policy - Minutes Builder Adopt and Publish (Rescind)`);
			obj = { ...obj, isPublishAndRescind: true };
			dispatch(publishPolicyAttachments(obj))
				.then((res) => {
					displaySuccessNotification(t("successMsg.publishrescind"), dispatch);
				})
				.catch((err) => {
					if (err.status === 500) {
						displayErrorNotification(t("errorMsg.publishrescind"), dispatch);
					} else {
						displayErrorNotification(t("errorMsg.commonMsg"), dispatch);
					}
				});
		}
	};

	const closeDialogs = () => {
		setDialogs({});
	};

	const parserOptions = {
		replace: (node) => {
			if (!node.attribs) return;
			if (["img", "br"].includes(node.name) && node.attribs.style) {
				node.attribs.style = "";
			}
			if (node.name === "p") {
				node.attribs.style = "margin-top: 12px; margin-bottom: 12px;";
			}
		},
	};

	const isMotionEmpty = () =>
		item.fields.Name.Value === "" &&
		item.fields.Text.Value === "" &&
		item.fields.MovedBy.Value === 0 &&
		item.fields.SecondedBy.Value === 0 &&
		item.fields.Disposition.Value === "";

	// #region Resolution Overflow Menu Code Section
	const handleToggleMenu = (e, type) => {
		e.stopPropagation();
		e.preventDefault();
		setAnchor({ [type]: e.currentTarget });
	};

	const handleCloseMenu = () => {
		if (anchor && anchor.votingTypes && typeof anchor.votingTypes.focus === "function") {
			anchor.votingTypes.focus();
		}
		setAnchor({});
	};

	const verifyDelete = () => {
		setDialogs((prev) => ({
			...prev,
			delete: true,
		}));
	};

	const resetVote = () => {
		setDialogs((prev) => ({
			...prev,
			resetVote: true,
		}));
	};

	(menuOptions || []).forEach((item) => {
		if (item.key === "reset-vote") {
			item.actionFunction = resetVote;
		}

		if (item.key === "delete-motion") {
			item.actionFunction = verifyDelete;
		}
	});

	// #endregion

	return (
		!item.deleted && (
			<div ref={setNodeRef}>
				{dialogs.publishPolicy && <LiveMeetingPolicyPublishDialog policyData={dialogs.publishPolicy} onClose={closeDialogs} />}
				{isHeading && isMemberOnlyHeading && !isSubHeading && (
					<li className={clsx(classes.headerListItem, classes.memberOnlyHeader)} data-cy={`agenda-header-member-only-${item.guid}`}>
						<Icon name="locked" color={whiteColor} size="16px" />
						<span className={classes.headerTextWithIcon}>{t("memberOnlySection")}</span>
					</li>
				)}
				{isHeading && isConsentHeading && !isSubHeading && (
					<li className={clsx(classes.headerListItem, classes.consentHeader)} data-cy={`agenda-header-consent-${item.guid}`}>
						<span>{t("consentSection")}</span>
					</li>
				)}
				{isHeading && isPublicCommentHeading && !isSubHeading && (
					<li className={clsx(classes.headerListItem, classes.publicCommentHeader)} data-cy={`agenda-header-public-comment-${item.guid}`}>
						<span>{t("publicCommentSection")}</span>
					</li>
				)}
				<div
					id={`agenda-${item.guid}`}
					className={clsx(classes.agendaListItem, {
						[dragAndDropClasses.dragPlaceholder]: dragPlaceholder,
						[classes.borderDefault]: !isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && !addBottomBorderToThis,
						[classes.borderDefaultBottomBorder]:
							!isMemberOnlyHeading && !isConsentHeading && !isPublicCommentHeading && addBottomBorderToThis,
						[classes.borderMemberOnly]: isMemberOnlyHeading,
						[classes.borderConsent]: isConsentHeading,
						[classes.borderPublicComment]: isPublicCommentHeading,
						[classes.selected]: isSelected,
					})}
					data-cy={`minutes-${item.guid}`}
				>
					{!readOnly && (
						<LiveMeetingRadioButton
							value={item.guid}
							label={fields.Name.Value}
							selected={isSelected}
							handleSelect={handleSelect}
							isMinutesV2
						/>
					)}
					<div
						className={clsx(classes.item, classes.liveMeetingItem, classes.rightBorder, {
							[classes.itemHeading]: isHeading,
							[classes.itemItem]: isItem,
							[classes.itemRecommendation]: isResolution,
							[classes.bottomBorder]: addBottomBorderToThis,
							[classes.itemSelected]: isSelected && (isMemberOnlyHeading || isConsentHeading || isPublicCommentHeading),
						})}
					>
						{canDrag && (
							<Draggable {...dragProps}>
								<DragHandle
									className={clsx({ [classes.dragHandleOffset]: !isResolution })}
									role="button"
									aria-label={t("meetings:templateDetail.tooltips.dragItem")}
								/>
							</Draggable>
						)}
						<div className={clsx(classes.textContainer)}>
							{(isHeading || isItem) && fields.Number.Value.length > 0 && (
								<div className={classes.minutesBullet}>{fields.Number.Value}</div>
							)}
							{isResolution && (
								<div className={classes.motionIcon} data-icon="recommendation">
									<Icon name="recommendation" color={blackColor[1]} />
								</div>
							)}
							<div className={classes.itemText}>
								{readOnly ? (
									<Box
										className={clsx(classes.editorFieldContent, classes.fieldContentMargin, {
											[classes.fieldReadOnly]: readOnly,
										})}
										data-fieldname={item.guid}
										ref={undefined}
									>
										{Parser(item.fields?.Name?.Value || "", parserOptions)}
									</Box>
								) : (
									<div className={classes.editorFieldWrapper}>
										<Box
											className={clsx(classes.editorFieldContent, classes.fieldContentMargin, {
												[classes.editorFieldContentWithMotion]: isResolution,
											})}
											data-fieldname={item.guid}
											ref={(el) => {
												afterElementRefSet(elementsIndex);
												elementsRef.current[elementsIndex] = el;
											}}
										/>
									</div>
								)}
								{showMenu && (
									<>
										{(canDrag || (!canDrag && isResolution)) && (
											<div className={classes.overflowMenu}>
												<AccessibleIconButton
													iconName="more"
													iconColor={blackColor[1]}
													aria-label={t("app:menu.options")}
													onClick={(e) => {
														e.stopPropagation();
														handleMenu(e, item.guid, { overflow: true });
													}}
													dataCy={`overflow-menu-${item.guid}`}
												/>
											</div>
										)}
										{overflowMenuOpen && menuOptions && menuOptions.length > 0 && (
											<NonModalMenu
												id="item-overflow-menu"
												className="overflow-menu"
												anchorEl={menuAnchor.anchor}
												keepMounted
												open={Boolean(menuAnchor.anchor)}
												onClose={handleMenu}
												position="bottom-end"
											>
												{menuOptions.map((option) => (
													<MenuItemWithTooltip
														key={option.label}
														tooltip={option.tooltip}
														placement="left"
														onClick={(e) => {
															option.actionFunction(e);
															handleMenu();
														}}
														separator={option.separator ? true : null}
														data-cy={`item-menu-option-${option["data-cy"]}`}
													>
														{option.label}
													</MenuItemWithTooltip>
												))}
											</NonModalMenu>
										)}
									</>
								)}
								{!readOnly && isResolution && (
									<Motion
										key={`motion${item.guid}`}
										motion={item}
										meeting={meeting}
										rollCall={rollCall}
										otherRollCallTypes={otherRollCallTypes}
										additionalUsers={additionalUsers}
										minutesItems={minutesItems}
										selected={isSelected ? selected : undefined}
										alwaysUseRecordedVote={meeting.alwaysUseRecordedVote}
										votingData={votingData}
										onlineVoters={onlineVoters}
										votingSettings={votingSettings}
										adoptUpdating={adoptUpdating}
										forceUpdate={forceUpdate}
										showSignIn={showSignIn}
										handleUpdateMotion={handleUpdateMotion}
										adoptPublishPreviousMinutes={adoptPublishPreviousMinutes}
										isSelected={isSelected || (active && active.includes(item.guid))}
										signalRClient={signalRClient}
										setShowVotingStatus={setShowVotingStatus}
										showOverflowMenu={false}
										showMotionDeleteDialog={dialogs.delete}
										closeDialogs={closeDialogs}
									/>
								)}
								{!readOnly && !meeting.isReadOnly && (active === item.guid || active === `${item.guid}-text`) && editorFieldTextDeleted && (
									<Box
										className="description-container"
										mr={6}
										mt={1}
										tabIndex={0}
										onClick={() => {
											addText(item.guid);
										}}
										onKeyPress={(e) => {
											if (e.key === "Enter" || e.key === " ") {
												addText(item.guid);
												e.preventDefault();
												e.stopPropagation();
											}
										}}
									>
										<Typography component="div">{t("addDescription")}</Typography>
									</Box>
								)}

								{!readOnly && (
									<ListItem
										disableGutters
										className={clsx(classes.descriptionContainer, {
											[classes.editorFieldHide]: editorFieldTextDeleted,
											[classes.fieldReadOnly]: readOnly,
										})}
									>
										<Box
											className={clsx(classes.editorFieldDescriptionContent, {
												[classes.fieldReadOnly]: readOnly,
											})}
											data-fieldname={`${item.guid}-text`}
											ref={
												!readOnly
													? (el) => {
															afterElementRefSet(elementsIndex + 1);
															elementsRef.current[elementsIndex + 1] = el;
														}
													: undefined
											}
										>
											{readOnly ? (
												Parser(item.fields?.Text?.Value || "", parserOptions)
											) : (
												<div className={classes.editorFieldPlaceHolder}></div>
											)}
										</Box>
										<ListItemSecondaryAction
											classes={{ root: "section-overflow-trash" }}
											className={active === `${item.guid}-text` && !readOnly && !editorFieldTextDeleted ? "" : classes.editorFieldHide}
											onClick={() => {
												removeText(item.guid);
											}}
										>
											<IconButton classes={{ root: "content-well-icon-button" }} size="large">
												<Icon name="trash" color={blackColor[1]}></Icon>
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								)}
								{!readOnly && (isSelected || (active && active.includes(item.guid))) && (
									<Box
										className={classes.buttons}
										mt={
											!meeting.isReadOnly && editorFieldTextDeleted
												? isSelected || active === item.guid || active === `${item.guid}-text`
													? 2
													: 0
												: 2
										}
									>
										<div className={clsx(classes.buttonsLeft, classes.startAlign)}>
											<ButtonGroup variant="contained" color="primary" aria-label="split button">
												<ButtonWithTooltip
													className="split-button"
													primary
													variant="outlined"
													title={t("tooltips.addNewMotion")}
													onClick={() => {
														if (!item || (item && !isMotionEmpty())) {
															handleAddMotion(item);
														}
													}}
													dataCy={`add-new-motion-${item.guid}`}
													aria-label={t("tooltips.addNewMotion")}
												>
													{`+ ${t("motions.panel.title")}`}
												</ButtonWithTooltip>
												<Button
													variant="outlined"
													className="split-icon-button"
													aria-controls={addMenuOpen ? "split-button-menu" : undefined}
													aria-expanded={addMenuOpen ? "true" : undefined}
													aria-label="select minutes action"
													aria-haspopup="menu"
													onClick={(e) => {
														e.stopPropagation();
														handleMenu(e, item.guid, { overflow: false });
													}}
													data-cy={`split-button-arrow-${item.guid}`}
												>
													<Icon name="expand-down" color={primaryColor[0]} />
												</Button>
											</ButtonGroup>
											{addMenuOpen && (
												<NonModalMenu
													id={`add-button-menu-${item.guid}`}
													className="overflow-menu"
													anchorEl={menuAnchor.anchor}
													open={Boolean(menuAnchor.anchor)}
													onClose={handleMenu}
												>
													{addItemMenuOptions.map((option) => (
														<MenuItem
															key={option.label}
															onClick={() => option.actionFunction(item)}
															data-cy={`add-menu-option-${option["data-cy"]}`}
														>
															{option.label}
														</MenuItem>
													))}
												</NonModalMenu>
											)}
											{policyEnabled &&
												!meeting.isReadOnly &&
												(active === item.guid || active === `${item.guid}-text`) &&
												isPolicyMotion &&
												votingPassed && (
													<>
														<ButtonWithTooltip
															className={classes.thinButton}
															variant="outlined"
															color="primary"
															primary
															onClick={(e) => {
																handleToggleMenuForPolicy(e, "publish");
															}}
															title={"Publish"}
															data-cy="publishPolicy"
															style={{ marginLeft: "5px" }}
														>
															{"Publish"}
														</ButtonWithTooltip>
														<ButtonWithTooltip
															variant="outlined"
															className={classes.thinButton}
															color="primary"
															primary
															title={"Select Policy publish option"}
															aria-controls="split-button-menu"
															aria-expanded="true"
															aria-label="select publish action"
															aria-haspopup="menu"
															tooltipPlacement="bottom"
															onClick={(e) => {
																setOpenMenu(!openMenu);
																setSelectedMenuOption({ ["publish"]: e.currentTarget });
															}}
															style={{ marginLeft: "3px" }}
														>
															<Icon name="expand-down" color={""} size="15px" />
														</ButtonWithTooltip>

														{openMenu && selectedMenuOption.publish && (
															<NonModalMenu
																id="publishunpublishmenuoption"
																anchorEl={selectedMenuOption.publish}
																keepMounted
																open={true}
																onClose={() => {}}
																options={publishPolicyMenuOptions}
																position="bottom-end"
															/>
														)}
													</>
												)}
											{showAdoptPublishPreviousMinutes && (
												<div style={{ margin: "0 8px" }}>
													<div>
														<ButtonWithTooltip
															className={clsx(classes.button, classes.adoptPublish, classes.thinButton)}
															primary
															variant="outlined"
															title={t("meetings:tooltips.adoptPublish")}
															onClick={() => {
																adoptPublishPreviousMinutes(minutesToAdoptMeetingId);
															}}
															data-cy="adopt-publish"
														>
															{t("meetings:buttons.adoptPublish")}
														</ButtonWithTooltip>
													</div>
													<div>
														<Link
															className={clsx("cursor-pointer", classes.signAdoptLink)}
															underline="always"
															href={`${API_HOST}/home/meeting/adopt/${minutesToAdoptMeetingId}/minutes?liveMeeting=${meeting.id}`}
														>
															{t("meetings:buttons.goToSignAdopt")}
														</Link>
													</div>
												</div>
											)}
										</div>
									</Box>
								)}
							</div>
						</div>
						{videoExists && <TimeStampItem item={item} isSelected={isSelected} presenting={presenting} isMinuteV2 />}
					</div>
				</div>

				<LiveMeetingAttachments
					attachments={attachments}
					item={item}
					isClosedMeeting={isClosedMeeting}
					isMemberOnlyHeading={isMemberOnlyHeading}
					isConsentHeading={isConsentHeading}
					isPublicCommentHeading={isPublicCommentHeading}
					addBottomBorder={addBottomBorder}
					selected={selected && !isSelected ? selected : undefined}
					noMargin
					handleSelect={handleSelect}
				/>
			</div>
		)
	);
};

LiveMeetingItem.defaultProps = {
	isClosedMeeting: false,
	isMemberOnlyHeading: false,
	isConsentHeading: false,
	isPublicCommentHeading: false,
	isSubHeading: false,
	addBottomBorder: false,
	hasNotes: false,
	handleSelect: undefined,
};

export default React.memo(LiveMeetingItem);
