import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import request from "superagent";
import clsx from "clsx";
import queryString from "query-string";
import { matchPath } from "react-router-dom";
import { withRouter } from "utils/router";
import { addMinutes, addHours, differenceInMinutes, getHours, setHours, getMinutes, setMinutes, format, parseISO, isValid } from "date-fns";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import { withStyles } from "@mui/styles";
import clone from "lodash/fp/clone";
import omit from "lodash/fp/omit";
import { API_HOST } from "config/env";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import OutlinedInput from "atlas/components/FormControls/OutlinedInput";
import OutlinedDatePicker from "atlas/components/FormControls/OutlinedDatePicker";
import SelectInput from "atlas/components/FormControls/SelectInput";
import StyledSwitch from "atlas/components/FormControls/StyledSwitch";
import { useWidthDown } from "atlas/utils/useWidth";
import withErrorHandling from "components/ErrorHOC";
import ComponentContainer from "atlas/components/ComponentContainer/ComponentContainer";
import { SettingsContext } from "contexts/Settings/SettingsContext";
import ButtonWithTooltip from "atlas/components/Buttons/ButtonWithTooltip";
import { MEDIUM } from "atlas/utils/buttonSize";
import typographyStyle from "atlas/assets/jss/components/typographyStyle";
import getProperty from "../../utils/caseInsensitiveProperty";
import { mapStateToProps } from "../../redux/app/mapStateToProps";
import { resetPageConfigs, updatePageConfigs } from "../../redux/app/actions";
import notifierMessage from "utils/notifierMessage";
import { getDurations, getNearestDurationOption, processTimes } from "utils/meetingTime";
import { setSnackbarOptions } from "redux/snackBar/actions";
import { setMeetingDetails, setNewMeetingFlag, createMeetingInProgress } from "../../redux/NewCopyAndMove/actions";
import telemetryAddEvent from "utils/telemetryAddEvent";

const withWidth = () => (WrappedComponent) => (props) => {
	const widthDownSm = useWidthDown("sm");

	return <WrappedComponent {...props} widthDownSm={widthDownSm} />;
};

const styles = () => ({
	smallText: {
		...typographyStyle.smallText,
		marginBottom: "-8px",
	},
	fieldInput: {
		width: "100%",
		marginTop: "16px",
		marginBottom: "16px",
		"& .MuiInputBase-root": {
			height: "40px",
			"& .MuiInputBase-input": {
				boxSizing: "border-box",
				height: "40px",
				paddingTop: "10.5px",
				paddingBottom: "10.5px",
			},
		},
	},
	timeInput: {},
	hoursMinutesColon: {
		position: "absolute",
		right: "-14px",
		top: "48px",
	},
	buttonContainer: {
		display: "flex",
		"& .button-tooltip": {
			margin: "0",
		},
	},
	switchLabel: {
		...typographyStyle.fieldLabel,
		marginTop: "12px",
		marginRight: "24px",
	},
	switchInstructions: {
		...typographyStyle.fieldLabel,
	},
	saveButtonContainer: {
		marginLeft: "auto",
	},
	saveButton: {
		margin: "0",
	},
});

class CreateMeeting extends Component {
	constructor(props) {
		super(props);

		const { t, location } = props;
		const { params: { id } = {} } = matchPath({ path: "/meeting/edit/:id", end: true }, location.pathname) || {};
		const search = queryString.parse(location.search) || {};
		const meetingTypeId = getProperty(search, "meetingTypeId") || 0;
		const finishUrl = getProperty(search, "finishUrl");
		const finishAction = getProperty(search, "finishAction");
		const today = new Date();
		this.state = {
			meetingTypes: [],
			isFetchingMeeting: id !== undefined,
			boardId: parseInt(getProperty(search, "boardId") || 0, 10),
			meeting: {
				id,
				meetingTypeId: typeof meetingTypeId === "string" ? parseInt(meetingTypeId, 10) : meetingTypeId,
				date: format(today, "yyyy-MM-dd"),
				startTime: format(today, "HH:mm:ss"),
				endTime: format(addHours(today, 1), "HH:mm:ss"),
				hour: getHours(today) + 1 > 12 ? getHours(today) + 1 - 12 : getHours(today) + 1,
				minute: 0,
				period: getHours(today) + 1 > 12 ? 2 : 1,
				duration: null,
				name: "",
				location: "",
			},
			periods: [
				{ label: props.t("am"), value: 1 },
				{ label: props.t("pm"), value: 2 },
			],
			hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
			minutes: [
				{ label: "00", value: 0 },
				{ label: "15", value: 0.25 },
				{ label: "30", value: 0.5 },
				{ label: "45", value: 0.75 },
			],
			durations: getDurations(t),
			creatingMeeting: false,
			finish: {
				url: finishUrl,
				action: finishAction,
			},
		};
		this.handleCreateMeeting = this.handleCreateMeeting.bind(this);
	}

	componentDidMount() {
		const { dispatch, t, isCopyMove: { handleControlMeeting = null, calledFromCopyMove = false } = {} } = this.props;
		const { meeting, finish } = this.state;
		this.loadMeetingTypes();
		const editing = meeting.id && meeting.id > 0;
		let backUrl = editing ? `/meeting/${meeting.id}` : "/meetings";
		if (finish.url) {
			backUrl = `${finish.url}?action=${finish.action ? finish.action : ""}`;
		}
		if (calledFromCopyMove) {
			handleControlMeeting(this.handleCreateMeeting, this);
		}

		dispatch(resetPageConfigs({}));
		dispatch(
			updatePageConfigs({
				title: t(editing ? "editMeetingDetails" : "buttons.createMeeting"),
				back: { url: backUrl },
				telemetryPage: "Meeting details",
			}),
		);
	}

	loadMeeting = (id) => {
		const { navigate, showSignIn } = this.props;

		request
			.get(`${API_HOST}/api/meeting/${id}`)
			.withCredentials()
			.then((res) => {
				if (res.body) {
					this.setState({
						meeting: processTimes(res.body),
						isFetchingMeeting: false,
					});
				}
			})
			.catch((err) => {
				if (err.status === 401) {
					navigate("/");
				} else {
					showSignIn(
						err,
						() => {
							this.loadMeeting(id);
						},
						() => {
							this.setState({ requestError: err });
						},
					);
				}
			});
	};

	loadMeetingTypes = () => {
		const { showSignIn, isCopyMove: { calledFromCopyMove = false, preSelectedMeetingType = null } = {} } = this.props;

		request
			.get(`${API_HOST}/api/meetingtypes?adminOnly=true`)
			.withCredentials()
			.then((res) => {
				if (res.body) {
					const { boardId, meeting } = this.state;
					if (boardId > 0) {
						meeting.meetingTypeId = ((res.body || []).find((meetingType) => meetingType.boardId === boardId) || { id: 0 }).id;
					}
					if (meeting.meetingTypeId === 0) {
						meeting.meetingTypeId = res.body.filter((meetingType) => !meetingType.archived)[0].id;
					}

					this.setState(
						{
							meetingTypes: res.body,
							meeting,
						},
						() => {
							if (calledFromCopyMove && preSelectedMeetingType) {
								this.handleMeetingTypeChange(preSelectedMeetingType, res.body);
							} else {
								this.handleMeetingTypeChange(meeting.meetingTypeId, res.body);
							}
							if (this.state.meeting.id) {
								this.loadMeeting(this.state.meeting.id);
							}
						},
					);
				}
			})
			.catch((err) => {
				showSignIn(err, () => {
					this.loadMeetingTypes();
				});
			});
	};

	validateMeeting = (singleField = null) => {
		const { t } = this.props;
		const { meetingTypeId, date, name, location } = this.state.meeting;

		// if we are only validating one field onBlur, keep any other errors, otherwise reset all errors
		const errors = singleField ? omit(singleField, this.state.errors) : {};

		if (!singleField || singleField === "meetingTypeId") {
			if (!meetingTypeId) {
				errors.meetingTypeId = t("errors.required", { fieldName: t("meetingTemplate") });
			}
		}

		if (!singleField || singleField === "date") {
			if (!date) {
				errors.date = t("errors.required", { field: t("date") });
			}
		}

		if (!singleField || singleField === "name") {
			if (!name) {
				errors.name = t("errors.required", { field: t("name") });
			}
		}
		if (!singleField || singleField === "location") {
			if (!location) {
				errors.location = t("errors.required", { field: t("location") });
			}
		}

		if (Object.keys(errors).length > 0) {
			this.setState({
				errors,
			});
			return false;
		}
		return true;
	};

	handleMeetingTypeChange = (id, meetingTypesFromDb = null) => {
		const { meeting, durations } = this.state;
		const { dateFormat } = this.context;
		let { meetingTypes } = this.state;
		// This is to manage the fields on initial load since state doesn't have meetingTypes yet, but we have it from the api call
		if (meetingTypes.length === 0 && meetingTypesFromDb) {
			meetingTypes = meetingTypesFromDb;
		}
		if (meetingTypes && (!meeting.id || meeting.id <= 0)) {
			const selectedMeetingType = meetingTypes.find((mt) => mt.id === id);
			if (selectedMeetingType) {
				const formattedDate = meeting.date ? format(parseISO(meeting.date), dateFormat) : "";

				meeting.meetingTypeId = selectedMeetingType.id;
				meeting.name = selectedMeetingType.name + (formattedDate ? " - " + formattedDate : "");
				meeting.location = selectedMeetingType.location;
				const defaultHour = getHours(parseISO(selectedMeetingType.startTime));
				meeting.hour = defaultHour > 12 ? defaultHour - 12 : defaultHour;
				const defaultMinute = getMinutes(parseISO(selectedMeetingType.startTime));
				meeting.minute = ((parseInt((defaultMinute + 7.5) / 15, 10) * 15) % 60) / 60;
				meeting.period = defaultHour > 12 ? 2 : 1;
				if (selectedMeetingType.endTime) {
					const startDateTime = parseISO(selectedMeetingType.startTime);
					const endDateTime = parseISO(selectedMeetingType.endTime);

					let currentDateStartTime = setHours(new Date(), getHours(startDateTime));
					currentDateStartTime = setMinutes(currentDateStartTime, getMinutes(startDateTime));
					let currentDateEndTime = setHours(new Date(), getHours(endDateTime));
					currentDateEndTime = setMinutes(currentDateEndTime, getMinutes(endDateTime));
					meeting.duration = getNearestDurationOption(differenceInMinutes(currentDateEndTime, currentDateStartTime) / 60, durations);
				} else {
					meeting.duration = null;
				}
				meeting.showPublicSiteToggle = selectedMeetingType.showPublicSiteToggle;
				meeting.showOnPortal = selectedMeetingType.showOnPortal;

				this.setState({ meeting, errors: undefined });
			}
		}
	};

	handleFieldChange = (e) => {
		const { meeting = {}, errors = {} } = this.state;
		const { target } = e;
		const name = target.id || target.name;

		// get all values in edit mode, update just the one that changed.
		const newEditValues = clone(meeting);
		newEditValues[name] = target.value;

		// clear validation errors when we change a field. (only the changed field)
		const newErrors = clone(errors);
		newErrors[name] = null;

		this.setState(
			{
				meeting: newEditValues,
				errors: newErrors,
			},
			() => {
				// for time fields, re-validate post-setState, as a selection change doesn't trigger the blur event.
				if (name === "hour" || name === "minute" || name === "period") {
					this.validateMeeting("minute");
					this.validateMeeting("hour");
					this.validateMeeting("period");
				}
			},
		);

		if (name === "meetingTypeId") {
			this.handleMeetingTypeChange(target.value);
		}
	};

	handleDateChange = (date) => {
		const { meeting = {}, meetingTypes = [], errors = {} } = this.state;
		const { dateFormat } = this.context;

		// get all values in edit mode, update just the one that changed.
		const newEditValues = clone(meeting);

		const meetingType = meetingTypes.find((meetingType) => meetingType.id === newEditValues.meetingTypeId);
		if (date && isValid(date)) {
			newEditValues.date = format(date, "yyyy-MM-dd");

			const formattedDate = newEditValues.date ? format(parseISO(newEditValues.date), dateFormat) : "";
			const meetingName = newEditValues.name;
			const lastIndex = meetingName.lastIndexOf(" - ");
			if (lastIndex > 0) {
				newEditValues.name = meetingName.substring(0, lastIndex + 1) + "- " + formattedDate;
			} else {
				newEditValues.name = meetingType.name + " - " + formattedDate;
			}
		} else {
			newEditValues.date = date;
			newEditValues.name = meetingType.name;
		}

		// clear validation errors when we change a field. (only the changed field)
		const newErrors = clone(errors);
		newErrors.date = null;
		newErrors.hour = null;
		newErrors.minute = null;
		newErrors.period = null;
		this.setState(
			{
				meeting: newEditValues,
				errors: newErrors,
			},
			() => {
				this.validateMeeting();
			},
		);
	};

	handleBlur = (e, field) => {
		this.validateMeeting(field);
	};

	handleShowOnPortal = (e, setting) => {
		const {
			target: { checked },
		} = e;

		const { meeting = {} } = this.state;

		const newEditValues = clone(meeting);
		newEditValues[setting] = checked;
		newEditValues["showPublicSiteToggle"] = checked;

		this.setState({ meeting: newEditValues });
	};

	finishCreation = (editing, url) => {
		const { t, navigate, dispatch, isCopyMove, afterMeetingCreated } = this.props;
		let option = notifierMessage(t(`notifications.${editing ? "meetingSaved" : "meetingCreated"}`), "success");
		dispatch(setSnackbarOptions(option));

		dispatch(updatePageConfigs({ retainFromUrlAsPrefered: true }));

		if (isCopyMove) {
			afterMeetingCreated();
		} else {
			navigate(url);
		}
	};

	handleCreateMeeting = (thisReference) => {
		const { showSignIn, afterMeetingCreated, dispatch, isCopyMove: { calledFromCopyMove = false } = {} } = this.props;
		const { meeting = {}, finish = {} } = this.state;

		if (this.validateMeeting()) {
			const newStartTime = new Date(
				1970,
				1,
				1,
				meeting.period && meeting.period === 1
					? meeting.hour === 12
						? 0
						: meeting.hour
					: meeting.hour === 12
						? meeting.hour
						: meeting.hour + 12,
				meeting.minute * 60,
				0,
			);
			meeting.startTime = format(newStartTime, "HH:mm:ss");
			meeting.endTime = meeting.duration > 0 ? format(addMinutes(newStartTime, meeting.duration * 60), "HH:mm:ss") : null;

			const editing = meeting.id && meeting.id > 0;
			const url = `${API_HOST}/api/meeting${editing ? `/${meeting.id}` : ""}`;
			const requestObject = editing ? request.put(url) : request.post(url);
			this.setState({ creatingMeeting: true });
			calledFromCopyMove && dispatch(createMeetingInProgress(true));
			requestObject
				.withCredentials()
				.send(meeting)
				.then((res) => {
					if (res.status === 200) {
						if (finish.url) {
							// Not going to the default page so finish meeting creation here
							request
								.post(`${API_HOST}/api/meeting/${res.body.id}/finishmeetingcreation`)
								.withCredentials()
								.send({})
								.then((response) => {
									if (response.body) {
										this.setState({ creatingMeeting: false });
										if (calledFromCopyMove) {
											afterMeetingCreated();
										} else {
											this.finishCreation(editing, `${finish.url}?action=${finish.action ? finish.action : ""}`);
										}
									}
								});
						} else {
							if (calledFromCopyMove) {
								dispatch(setMeetingDetails(res.body));
								request
									.post(`${API_HOST}/api/meeting/${res.body.id}/finishmeetingcreation`)
									.withCredentials()
									.send({})
									.then(() => {
										thisReference.setState({ creatingMeeting: false });
										dispatch(setNewMeetingFlag(true));
										afterMeetingCreated();
										dispatch(createMeetingInProgress(false));
										telemetryAddEvent("Copy/Move - New Meeting Created");
									});
							} else {
								this.setState({ creatingMeeting: false });
								this.finishCreation(editing, `/meeting/${res.body.id}`);
							}
						}
					} else {
						this.setState({ creatingMeeting: false });
					}
				})
				.catch((err) => {
					showSignIn(err, () => {
						this.handleCreateMeeting();
					});
				});
		}
	};

	render() {
		const { t, widthDownSm, classes, isCopyMove: { calledFromCopyMove = false, preSelectedMeetingType = null } = {} } = this.props;
		const {
			errors = {},
			isFetchingMeeting,
			meetingTypes,
			meeting,
			hours,
			minutes,
			periods,
			durations,
			creatingMeeting,
			requestError,
		} = this.state;
		const { dateFormat } = this.context;
		const editing = meeting.id && meeting.id > 0;
		if (requestError) {
			const errormsg =
				requestError.response.body && requestError.response.body.Message
					? requestError.response.body.Message
					: `${requestError.status} ${requestError.message}`;
			return <Typography variant="h3">{errormsg}</Typography>;
		}
		if (meetingTypes.length === 0 || isFetchingMeeting) {
			return <CircularProgressIndicator />;
		}

		return (
			<ComponentContainer onlyScrollbar>
				<Container maxWidth="xs" className={`narrow-form padded-top padded-bottom${widthDownSm ? " mobile" : ""}`}>
					<SelectInput
						id="meetingTypeId"
						name="meetingTypeId"
						className={classes.fieldInput}
						noDefaultClassName
						fullWidth
						size="small"
						label={t("meetingTemplate")}
						tooltipText={editing ? t("tooltips.meetingTemplateCantBeChanged") : null}
						tooltipPlacement="bottom"
						value={meetingTypes.length > 0 ? meeting.meetingTypeId || "" : ""}
						onChange={(e) => this.handleFieldChange(e, true)}
						onBlur={(e) => this.handleBlur(e, "meetingTypeId")}
						helperText={errors.meetingTypeId ? errors.meetingTypeId : ""}
						error={!!errors.meetingTypeId}
						disabled={editing}
						data-cy="meetingType"
					>
						{meetingTypes
							.filter((meetingType) => !meetingType.archived || meetingType.id === meeting?.meetingTypeId)
							.map((meetingType) => (
								<MenuItem key={meetingType.id} value={meetingType.id}>
									{meetingType.name}
								</MenuItem>
							))}
					</SelectInput>
					<OutlinedDatePicker
						id="date"
						className={classes.fieldInput}
						label={t("date")}
						iconTooltip={t("date")}
						dateFormat={dateFormat}
						value={meeting.date}
						onChange={this.handleDateChange}
						invalidDateMessage={errors.date}
						dataCy="date"
					></OutlinedDatePicker>
					<OutlinedInput
						className={classes.fieldInput}
						noDefaultClassName
						id="name"
						label={t("name")}
						onChange={this.handleFieldChange}
						onBlur={(e) => this.handleBlur(e, "name")}
						helperText={errors.name}
						fullWidth
						size="small"
						value={meeting.name || ""}
						error={!!errors.name}
						data-cy="name"
					/>
					<Grid container spacing={3}>
						<Grid item xs={4} className="absolute-container">
							<SelectInput
								id="hour"
								name="hour"
								className={clsx(classes.fieldInput, classes.timeInput)}
								noDefaultClassName
								fullWidth
								label={t("hour")}
								value={meeting.hour}
								onChange={(e) => this.handleFieldChange(e)}
								onBlur={(e) => this.handleBlur(e, "hour")}
								helperText={errors.hour ? errors.hour : ""}
								error={!!errors.hour}
								data-cy="startTimeHour"
							>
								{hours.map((hour) => (
									<MenuItem key={`hour-${hour}`} value={hour} data-cy={`startTimeHour${hour}`}>
										{hour}
									</MenuItem>
								))}
							</SelectInput>
							<div className={classes.hoursMinutesColon}>:</div>
						</Grid>
						<Grid item xs={4}>
							<SelectInput
								id="minute"
								name="minute"
								className={clsx(classes.fieldInput, classes.timeInput)}
								noDefaultClassName
								fullWidth
								label={t("minute")}
								value={meeting.minute}
								onChange={(e) => this.handleFieldChange(e)}
								onBlur={(e) => this.handleBlur(e, "minute")}
								error={!!errors.hour}
								data-cy="startTimeMinute"
							>
								{minutes.map((minute) => (
									<MenuItem key={`minute-${minute.value}`} value={minute.value} data-cy={`startTimeMinute${minute.value}`}>
										{minute.label}
									</MenuItem>
								))}
							</SelectInput>
						</Grid>
						<Grid item xs={4}>
							<SelectInput
								id="period"
								name="period"
								className={clsx(classes.fieldInput, classes.timeInput)}
								noDefaultClassName
								fullWidth
								label={t("period")}
								value={meeting.period || ""}
								onChange={(e) => this.handleFieldChange(e)}
								onBlur={(e) => this.handleBlur(e, "period")}
								error={!!errors.hour}
								data-cy="startTimePeriod"
							>
								{periods.map((period) => (
									<MenuItem key={`period-${period.value}`} value={period.value} data-cy={`startTimePeriod${period.value}`}>
										{period.label}
									</MenuItem>
								))}
							</SelectInput>
						</Grid>
					</Grid>
					<SelectInput
						id="duration"
						name="duration"
						className={classes.fieldInput}
						noDefaultClassName
						fullWidth
						size="small"
						label={t("durationOptional")}
						value={meeting.duration || ""}
						onChange={(e) => this.handleFieldChange(e)}
						data-cy="duration"
					>
						{durations.map((duration) => (
							<MenuItem key={`duration-${duration.value}`} value={duration.value || ""} data-cy={`duration${duration.value}`}>
								{duration.label}
							</MenuItem>
						))}
					</SelectInput>
					<OutlinedInput
						className={classes.fieldInput}
						noDefaultClassName
						id="location"
						label={t("location")}
						onChange={this.handleFieldChange}
						onBlur={(e) => this.handleBlur(e, "location")}
						helperText={errors.location}
						fullWidth
						size="small"
						value={meeting.location || ""}
						error={!!errors.location}
						data-cy="location"
					/>

					<div className={classes.buttonContainer}>
						{
							<div>
								<StyledSwitch
									classes={{
										label: classes.switchLabel,
										stateLabel: classes.switchInstructions,
									}}
									useLabel={true}
									label={t("publicSite")}
									onLabel={t("show")}
									offLabel={t("hide")}
									inline
									title=""
									size="small"
									objectToUpdate={meeting}
									fieldToUpdate="showOnPortal"
									onChange={(checked) => this.handleShowOnPortal({ target: { checked } }, "showOnPortal")}
									data-cy="showOnPortal"
								/>
							</div>
						}
						{!calledFromCopyMove && (
							<div className={classes.saveButtonContainer}>
								<ButtonWithTooltip
									title={
										errors.date || errors.hour || errors.name || errors.location || errors.meetingTypeId ? t("app:tooltips.fixErrors") : ""
									}
									id="create-meeting"
									className={classes.saveButton}
									disabled={
										!!errors.date || !!errors.hour || !!errors.meetingTypeId || !!errors.name || !!errors.location || creatingMeeting
									}
									onClick={this.handleCreateMeeting}
									data-cy="save"
									primary
									size={MEDIUM}
									fullWidth={widthDownSm}
								>
									{!creatingMeeting ? t(editing ? "app:buttons.save" : "buttons.createMeeting") : null}
									{creatingMeeting && <CircularProgressIndicator minHeight="0px" size={24} padding="8px" />}
								</ButtonWithTooltip>
							</div>
						)}
					</div>
				</Container>
			</ComponentContainer>
		);
	}
}

CreateMeeting.contextType = SettingsContext;

export default withRouter(
	withTranslation("meetings")(withWidth()(withErrorHandling(connect(mapStateToProps)(withStyles(styles)(CreateMeeting))))),
);
