export const ADD_MOTION = "ADD_MOTION";
export const ADD_TEXT = "ADD_TEXT";
export const CHECK_FILE_UPLOADS = "CHECK_FILE_UPLOADS";
export const DELETE_MOTION = "DELETE_MOTION";
export const GET_AGENDA_ITEMS_FULFILLED = "GET_AGENDA_ITEMS_FULFILLED";
export const GET_AGENDA_ITEMS_PENDING = "GET_AGENDA_ITEMS_PENDING";
export const GET_MEETING_GROUPS_FULFILLED = "GET_MEETING_GROUPS_FULFILLED";
export const GET_MEETING_GROUPS_PENDING = "GET_MEETING_GROUPS_PENDING";
export const GET_MEMBERS_FULFILLED = "GET_MEMBERS_FULFILLED";
export const GET_MEMBERS_PENDING = "GET_MEMBERS_PENDING";
export const GET_MINUTES_ITEMS_FULFILLED = "GET_MINUTES_ITEMS_FULFILLED";
export const GET_MINUTES_ITEMS_PENDING = "GET_MINUTES_ITEMS_PENDING";
export const GET_PLACEHOLDER_VALUES_FULFILLED = "GET_PLACEHOLDER_VALUES_FULFILLED";
export const GET_PLACEHOLDER_VALUES_PENDING = "GET_PLACEHOLDER_VALUES_PENDING";

export const PERSIST_FILE_FULFILLED = "PERSIST_FILE_FULFILLED";
export const PERSIST_FILE_PENDING = "PERSIST_FILE_PENDING";
export const PERSIST_MINUTES_FULFILLED = "PERSIST_MINUTES_FULFILLED";
export const PERSIST_MINUTES_PENDING = "PERSIST_MINUTES_PENDING";
export const PERSIST_MINUTES_FAILED = "PERSIST_MINUTES_FAILED";
export const PERSIST_VOTE_FULFILLED = "PERSIST_VOTE_FULFILLED";
export const PERSIST_VOTE_PENDING = "PERSIST_VOTE_PENDING";

export const QUEUE_FILE_UPLOADS = "QUEUE_FILE_UPLOADS";

export const REMOVE_TEXT = "REMOVE_TEXT";
export const RESET_MEETING = "RESET_MEETING";

export const SEND_FOR_VOTE_FULFILLED = "SEND_FOR_VOTE_FULFILLED";
export const SEND_FOR_VOTE_PENDING = "SEND_FOR_VOTE_PENDING";

export const SET_ACTIVE = "SET_MEETING_ACTIVE";
export const SET_SELECTED = "SET_SELECTED";
export const SET_PRESENTING = "SET_PRESENTING";

export const STOP_VOTE_PENDING = "STOP_VOTE_PENDING";
export const STOP_VOTE_FULFILLED = "STOP_VOTE_FULFILLED";

export const TIMESTAMP_ITEM_FULFILLED = "TIMESTAMP_ITEM_FULFILLED";
export const TIMESTAMP_ITEM_PENDING = "TIMESTAMP_ITEM_PENDING";

export const UPDATE_MEETING = "UPDATE_MEETING";
export const UPDATE_MEETING_DATE = "UPDATE_MEETING_DATE";
export const UPDATE_MEETING_VOTING_DATA = "UPDATE_MEETING_VOTING_DATA";
export const UPDATE_MINUTES_ITEMS = "UPDATE_MINUTES_ITEMS";
export const UPDATE_MOTION = "UPDATE_MOTION";
export const UPDATE_VOTE = "UPDATE_VOTE";
export const START_TIEBREAKER_VOTE_PENDING = "START_TIEBREAKER_VOTE_PENDING";
export const START_TIEBREAKER_VOTE_FULFILLED = "START_TIEBREAKER_VOTE_FULFILLED";

export const ADD_MINUTES_EDITOR_ADD_ITEM = "ADD_MINUTES_EDITOR_ADD_ITEM";
export const REORDER_MINUTES_BUILDER_MEETING_ITEMS = "REORDER_MINUTES_BUILDER_MEETING_ITEMS";
export const ADD_MINUTES_EDITOR_AGENDA_MEETING_ITEM = "ADD_MINUTES_EDITOR_AGENDA_MEETING_ITEM";
export const DELETE_MINUTES_EDITOR_AGENDA_MEETING_ITEM = "DELETE_MINUTES_EDITOR_AGENDA_MEETING_ITEM";
export const UPDATE_MINUTES_EDITOR_MINUTE_ITEMS = "UPDATE_MINUTES_EDITOR_MINUTE_ITEMS";
