import React, { useEffect, useState } from "react";
import TableOfContents from "components/Meeting/TableOfContents";
import cloneDeep from "lodash/cloneDeep";
import { Checkbox } from "@mui/material";
import { stripHtml } from "utils/processHtml";
import {
	setOverallSelectedMeetingItems,
	setSmartSelectedItems,
	setSmartSelectToggle,
	setRightAgendaMap,
	setLeftAgendaObject,
} from "redux/NewCopyAndMove/actions";
import { useDispatch, useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgressIndicator from "atlas/components/Progress/CircularProgressIndicator";
import AntSwitch from "../utilsComponent/AntSwitch";
import {
	assignBriefingNotesIcon,
	childModifier,
	createObjectsToInsert,
	createRightAgendaMap,
	insertItemsIntoRightAgenda,
	parentModifier,
	recordOriginalItemOrder,
	setWarningForMemberOnlyItems,
	subheadingModifier,
} from "../utils/copyMovefunctions";
import { fetchAgendaItems, setFinalRightMeetingAgenda, setWarningMessage } from "redux/NewCopyAndMove/actions";
import { SET_RIGHT_MEETING_AGENDA } from "redux/NewCopyAndMove/types";
import Warning from "../utilsComponent/Warning";
import { getSharedOrPublished, handleKeyDownForFromAgenda, handleKeyDownForSwitch } from "../utils/functions";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import ToAgendaListPage from "../ToAgendaListPage";
import { reOrderItemArray } from "views/MeetingEditor/functions/utils";
import { focusColor } from "atlas/assets/jss/shared";
import Icon from "atlas/components/Icon/Icon";
import Tooltip from "atlas/components/Tooltip/Tooltip";
import notifierMessage from "utils/notifierMessage";
import { setSnackbarOptions } from "redux/snackBar/actions";
import telemetryAddEvent from "utils/telemetryAddEvent";

const useStyles = makeStyles({
	smartSelectContainer: {
		display: " flex",
		justifyContent: " flex-end",
		alignItems: "center",
		padding: "18px 0px 18px 0px",
		gap: "10px",
	},
	smartSelectText: {
		fontWeight: "600",
		fontSize: "16px",
	},
	agendaItemsList: {
		overflowY: "auto",
		height: "calc(100vh - 235px)",
	},
	agendaItemsListWithWarning: {
		overflowY: "auto",
		height: "calc(100vh - 320px)",
	},
	tabFocusStyling: {
		"&:focus-visible ": {
			outline: `solid 2px ${focusColor}`,
			outlineOffset: "2px",
			borderRadius: "12px",
		},
	},
	briefingNotesSpan: {
		position: "relative",
		top: "2px",
		right: "22px",
	},
});

const FromAgendaListPage = (props) => {
	const { parentMeetingId, callBackforFromAgendaListPage, copyOrMove, isOverflow = false, isCopy, callBackforToAgendaListPage } = props;
	const dispatch = useDispatch();
	const [objectForSelectedMeetingItems, setObjectForSelectedMeetingItems] = useState(null);
	const classes = useStyles();
	const { t } = useTranslation();
	const {
		overallSelectedMeetingItems,
		smartSelectedItems,
		defaultSmartSelectEnabled,
		selectedMeetingId,
		leftAgendaObject: objectForAgendaItems,
		rightAgendaMap: overallMapForRightAgendaItems,
		originalLeftMeetingAgendaItems: agendaItems,
		originalRightMeetingAgendaItems: selectedMeetingAgendaItems,
		agendaItemsForOverflow,
		finalRightMeetingAgendaItems,
	} = useSelector((state) => state.newCopyAndMoveReducer);

	useEffect(() => {
		if (parentMeetingId && selectedMeetingId && !selectedMeetingAgendaItems) {
			dispatch(fetchAgendaItems(selectedMeetingId))
				.then((response) => {
					response.items.length > 0 && assignBriefingNotesIcon(response);
					dispatch({
						type: SET_RIGHT_MEETING_AGENDA,
						payload: response,
					});
				})
				.catch((error) => {
					if (error.status === 403 && error.response.body.Message === "Access Denied") {
						let option = notifierMessage("Access Denied", "error");
						dispatch(setSnackbarOptions(option));
						setLoading(false);
						navigate(`/meeting/detailsV2/${id}`);
					} else {
						let option = notifierMessage("Something went wrong.Please Try again", "error");
						dispatch(setSnackbarOptions(option));
						setLoading(false);
						navigate(`/meeting/detailsV2/${id}`);
					}
				});
		}
	}, [parentMeetingId, selectedMeetingId, selectedMeetingAgendaItems]);

	const isDuplicatePresent = (isMainPresent, owntitle, itemType, isSubheadingPresent) => {
		let isAgendaItemPresent = false;
		if (isMainPresent) {
			if (isSubheadingPresent) {
				let mainSubheadingChildrenObj = isMainPresent["subheading"]?.[isSubheadingPresent]?.childrenAgendaItems;
				if (mainSubheadingChildrenObj?.[owntitle]) {
					isAgendaItemPresent = mainSubheadingChildrenObj?.[owntitle];
				}
			} else if (itemType === 3) {
				let mainAgendaItemObj = isMainPresent.childrenAgendaItems;
				if (mainAgendaItemObj?.[owntitle]) {
					isAgendaItemPresent = mainAgendaItemObj?.[owntitle];
				}
			} else if (itemType === 10) {
				let mainsubHeadingObject = isMainPresent.subheading;
				if (mainsubHeadingObject?.[owntitle]) {
					isAgendaItemPresent = mainsubHeadingObject?.[owntitle];
				}
			}
		}
		return isAgendaItemPresent;
	};

	useEffect(() => {
		const isObjectEmpty = Object.keys(objectForAgendaItems).length === 0;
		if (isObjectEmpty && agendaItems?.items && selectedMeetingAgendaItems) {
			const obj = {};
			const overallSelectedValueObject = {};
			const initialSmartCheckedItems = {};
			const sameParents = [];
			let mainParentGuid = null;
			let subheadingParentGuid = null;
			let ifMainParentPresentOnRight = false;
			let itemType3Order = 1;
			let itemType10Order = 1;
			let itemType10SubheadingOrder = 1;
			let itemType3SubheadingOrder = 1;
			let itemType7RecommendationOrder = 1;
			let itemType7RecommendationSubheadingOrder = 1;
			const itemToBeOperated = isOverflow ? agendaItemsForOverflow : cloneDeep(agendaItems.items);
			itemToBeOperated?.forEach((element, index) => {
				const {
					fields: { Name, Order },
					itemType,
					guid,
					attributes: { relationshipGuid },
					isItemSelectedFromOverflow = false,
				} = element;
				const title = stripHtml(Name.Value);
				let processedTitle = title.replace(/&nbsp;/g, "").replace(/\s+/g, "");
				// Convert to lowercase
				processedTitle = processedTitle.toLowerCase();
				//creating a object for left side meeting for one stop solution in future, when moving a item whatever decision needs to be made, it will be made from this object
				//Idea is to have a item of type 3 and 10(including subheading), their guid as a key and their children will be stored.
				//templeftobj needs to be created which will have parent as it's guid and if itemPrsent is true for section then for that guid it should be false, in that guid it will cover all it's direct children and inside that children , if has more children that will come as enabled true or false
				if (!relationshipGuid) {
					mainParentGuid = guid;
					if (objectForSelectedMeetingItems[processedTitle]) {
						ifMainParentPresentOnRight = objectForSelectedMeetingItems[processedTitle];
						sameParents.push(ifMainParentPresentOnRight.guid);
					} else {
						//Extra check to remove &nbsp; from the end of the title if title not getting matched
						ifMainParentPresentOnRight = false;
					}
					element["mainOrderAsPerType"] = itemType10Order;
					obj[guid] = {
						itemDetails: element,
						title: title,
						isMainParent: true,
						criteria: { order: Order.Value, itemType: itemType, index },
						isItemPresent: ifMainParentPresentOnRight,
						children: [],
						allChildren: [],
						itemType,
						mainOrderAsPerType: itemType10Order,
					};
					if (isOverflow && isItemSelectedFromOverflow) {
						initialSmartCheckedItems[guid] = true;
					} else if (!isOverflow && !ifMainParentPresentOnRight) {
						initialSmartCheckedItems[guid] = true;
					}
					overallSelectedValueObject[guid] = {
						enabled: isOverflow && isItemSelectedFromOverflow ? true : !isOverflow && !ifMainParentPresentOnRight ? true : false,
					};
					itemType10Order++;
					itemType10SubheadingOrder = 1;
					itemType3Order = 1;
					itemType3SubheadingOrder = 1;
					itemType7RecommendationOrder = 1;
					itemType7RecommendationSubheadingOrder = 1;
					subheadingParentGuid = null;
				} else if (itemType === 10) {
					overallSelectedValueObject[guid] = relationshipGuid;
					subheadingParentGuid = guid;
					let isItemPresent = ifMainParentPresentOnRight && isDuplicatePresent(ifMainParentPresentOnRight, processedTitle, itemType);
					element["mainOrderAsPerType"] = itemType10SubheadingOrder;
					obj[guid] = {
						itemDetails: element,
						title: title,
						criteria: { order: Order.Value, itemType: itemType, index },
						isItemPresent,
						children: [],
						itemType,
						allChildren: [],
						mainOrderAsPerType: itemType10SubheadingOrder,
					};
					obj[relationshipGuid]["children"] = [...(obj[relationshipGuid].children || {}), element];
					obj[mainParentGuid]["allChildren"] = [...(obj[mainParentGuid].allChildren || {}), element];
					if (isOverflow && isItemSelectedFromOverflow) {
						initialSmartCheckedItems[guid] = true;
					} else if (!isOverflow && !isItemPresent) {
						initialSmartCheckedItems[guid] = true;
					}
					overallSelectedValueObject[relationshipGuid] = {
						...(overallSelectedValueObject[relationshipGuid] || {}),
						[guid]: {
							...(overallSelectedValueObject[relationshipGuid][guid] || {}),
							enabled: isOverflow && isItemSelectedFromOverflow ? true : !isOverflow && !isItemPresent ? true : false,
						},
					};
					itemType10SubheadingOrder++;
					itemType3SubheadingOrder = 1;
					itemType7RecommendationSubheadingOrder = 1;
				} else if (itemType === 3) {
					let mainOrderAsPerType = null;
					let isItemPresent = false;
					if (obj[relationshipGuid]["isMainParent"]) {
						overallSelectedValueObject[guid] = relationshipGuid;
						isItemPresent = isDuplicatePresent(ifMainParentPresentOnRight, processedTitle, itemType);
						overallSelectedValueObject[relationshipGuid][guid] =
							isOverflow && isItemSelectedFromOverflow ? true : !isOverflow && !isItemPresent ? true : false;
						element["mainOrderAsPerType"] = itemType3Order;
						mainOrderAsPerType = itemType3Order;
						itemType3Order++;
					} else {
						overallSelectedValueObject[guid] = [mainParentGuid, relationshipGuid];
						let parentSubheadingTitle = obj[relationshipGuid]["title"];
						let parentProcessedTitle = parentSubheadingTitle
							.replace(/&nbsp;/g, "")
							.replace(/\s+/g, "")
							.toLowerCase();
						isItemPresent = isDuplicatePresent(ifMainParentPresentOnRight, processedTitle, itemType, parentProcessedTitle);
						overallSelectedValueObject[mainParentGuid][relationshipGuid][guid] =
							isOverflow && isItemSelectedFromOverflow ? true : !isOverflow && !isItemPresent ? true : false;
						element["mainOrderAsPerType"] = itemType3SubheadingOrder;
						mainOrderAsPerType = itemType3SubheadingOrder;
						itemType3SubheadingOrder++;
						if (subheadingParentGuid) {
							obj[subheadingParentGuid]["allChildren"] = [...(obj[subheadingParentGuid].allChildren || {}), element];
						}
					}
					if (isOverflow && isItemSelectedFromOverflow) {
						initialSmartCheckedItems[guid] = true;
					} else if (!isOverflow && !isItemPresent) {
						initialSmartCheckedItems[guid] = true;
					}
					obj[guid] = {
						itemDetails: element,
						title: title,
						criteria: { order: Order.Value, itemType: itemType, index },
						children: [],
						isItemPresent,
						itemType,
						mainOrderAsPerType,
					};
					obj[relationshipGuid]["children"] = [...(obj[relationshipGuid].children || {}), element];
					obj[mainParentGuid]["allChildren"] = [...(obj[mainParentGuid].allChildren || {}), element];
				} else {
					if (obj[relationshipGuid]["isMainParent"]) {
						element["mainOrderAsPerType"] = itemType7RecommendationOrder;
						itemType7RecommendationOrder++;
					} else if (relationshipGuid === subheadingParentGuid) {
						element["mainOrderAsPerType"] = itemType7RecommendationSubheadingOrder;
						itemType7RecommendationSubheadingOrder++;
						obj[subheadingParentGuid]["allChildren"] = [...(obj[subheadingParentGuid].allChildren || {}), element];
					} else {
						let currentParentObj = obj[relationshipGuid];
						let parentRelationshipGuid = currentParentObj["itemDetails"]["attributes"]["relationshipGuid"];
						if (parentRelationshipGuid === subheadingParentGuid) {
							obj[subheadingParentGuid]["allChildren"] = [...(obj[subheadingParentGuid].allChildren || {}), element];
						}
					}
					obj[guid] = {
						itemDetails: element,
						relationshipGuid: relationshipGuid,
						itemType: itemType,
					};
					obj[relationshipGuid]["children"] = [...(obj[relationshipGuid].children || {}), element];
					obj[mainParentGuid]["allChildren"] = [...(obj[mainParentGuid].allChildren || {}), element];
				}
			});
			dispatch(setSmartSelectedItems(initialSmartCheckedItems));
			dispatch(setOverallSelectedMeetingItems(overallSelectedValueObject));
			dispatch(setLeftAgendaObject(obj));
			setOverallSelectedItemsForSmartSelect(initialSmartCheckedItems, overallSelectedValueObject, obj, defaultSmartSelectEnabled);
			let map = createRightAgendaMap(selectedMeetingAgendaItems, sameParents);
			dispatch(setRightAgendaMap(map));
			console.log(overallSelectedValueObject, "overallSelectedValueObject");
		}
	}, [agendaItems, objectForSelectedMeetingItems]);

	useEffect(() => {
		//Creating right side agenda items object to match for parent title,agenda item and subheading
		let obj = {};
		let mainParent = null;
		let mainParentGuid = null;
		let subheadingParent = null;
		selectedMeetingAgendaItems?.items?.forEach((element, index) => {
			const {
				fields: { Name, Order },
				itemType,
				guid,
				attributes: { relationshipGuid },
			} = element;
			let title = stripHtml(Name.Value);
			title = title.replace(/&nbsp;/g, "").replace(/\s+/g, "");
			// Convert to lowercase
			title = title.toLowerCase();
			if (!relationshipGuid) {
				mainParent = title;
				mainParentGuid = guid;
				obj[title] = {
					guid,
					childrenAgendaItems: {},
					subheading: {},
					criteria: { itemType, order: Order.Value, index },
				};
			} else if (itemType === 3 && mainParent && relationshipGuid === mainParentGuid) {
				obj[mainParent]["childrenAgendaItems"] = { ...obj[mainParent]["childrenAgendaItems"], [title]: guid };
			} else if (itemType === 10 && mainParent) {
				subheadingParent = title;
				obj[mainParent]["subheading"][title] = { ...(obj[mainParent]["subheading"] || {}), guid, index };
			} else if (itemType === 3 && relationshipGuid !== mainParentGuid && subheadingParent) {
				obj[mainParent]["subheading"][subheadingParent]["childrenAgendaItems"] = {
					...(obj[mainParent]["subheading"][subheadingParent]["childrenAgendaItems"] || {}),
					[title]: guid,
				};
			}
		});

		setObjectForSelectedMeetingItems(obj);
	}, [selectedMeetingAgendaItems]);

	useEffect(() => {
		if (isOverflow && overallSelectedMeetingItems && Object.keys(objectForAgendaItems).length > 0 && overallMapForRightAgendaItems) {
			let itemsToInsert = createObjectsToInsert(
				overallSelectedMeetingItems,
				objectForAgendaItems,
				overallMapForRightAgendaItems,
				isCopy,
				dispatch,
				setWarningMessage,
			);
			let newRightAgendaItems = cloneDeep(selectedMeetingAgendaItems.items);
			newRightAgendaItems && recordOriginalItemOrder(newRightAgendaItems);
			let tempArray = insertItemsIntoRightAgenda(itemsToInsert, newRightAgendaItems);
			let agendaNumberingProps = { props: { agendaNumbering: selectedMeetingAgendaItems.agendaNumbering }, itemIdsToUpdate: [] };
			reOrderItemArray(tempArray, agendaNumberingProps, selectedMeetingAgendaItems.customNumbering);
			setWarningForMemberOnlyItems(tempArray, isCopy, dispatch, setWarningMessage);
			dispatch(setFinalRightMeetingAgenda(tempArray));
		}
	}, [isOverflow, overallSelectedMeetingItems, objectForAgendaItems, overallMapForRightAgendaItems]);

	const getWarningText = (boardStatus, publicStatus) => {
		const warnings = [];
		const status = getSharedOrPublished(boardStatus, publicStatus);
		if (status === "shared") {
			warnings.push(t(`app:move.fromShared`));
		} else if (status === "published") {
			warnings.push(t(`app:move.fromPublished`));
		}
		return warnings;
	};

	const handleToggle = (e, item) => {
		const itemGuids = {};
		const {
			guid,
			itemType,
			attributes: { relationshipGuid },
		} = item;
		itemGuids[guid] = e.target.checked;
		if (itemType === 10 && !relationshipGuid) {
			parentModifier(guid, e.target.checked, overallSelectedMeetingItems, dispatch, setOverallSelectedMeetingItems);
		} else if ((itemType === 10 && relationshipGuid) || (itemType === 3 && !objectForAgendaItems[relationshipGuid]?.isMainParent)) {
			subheadingModifier(guid, e.target.checked, itemType, overallSelectedMeetingItems, dispatch, setOverallSelectedMeetingItems);
		} else if (itemType === 3 && relationshipGuid) {
			childModifier(guid, e.target.checked, overallSelectedMeetingItems, dispatch, setOverallSelectedMeetingItems);
		}
		callBackforFromAgendaListPage();
	};

	const checkHanderForSelectableItems = (item) => {
		const { guid, itemType, attributes: { relationshipGuid = null } = {} } = item;
		if (itemType === 10) {
			return !relationshipGuid
				? overallSelectedMeetingItems[guid]?.enabled
				: overallSelectedMeetingItems[relationshipGuid]?.[guid]?.enabled;
		}
		if (itemType === 3) {
			const parentGuid = overallSelectedMeetingItems[guid];
			if (parentGuid && Array.isArray(parentGuid)) {
				return parentGuid.reduce((acc, key) => acc[key], overallSelectedMeetingItems)[guid];
			} else {
				return overallSelectedMeetingItems[parentGuid]?.[guid];
			}
		}
	};

	const checkHandlerForUnSelectableItems = (item) => {
		const { itemType, attributes: { relationshipGuid = null } = {}, itemGuid = null } = item;
		if (itemType === 7 && relationshipGuid) {
			const parentDetails = objectForAgendaItems[relationshipGuid]?.itemDetails;
			return checkHanderForSelectableItems(parentDetails);
		} else if (itemGuid) {
			const tempParentDetails = objectForAgendaItems[itemGuid]?.itemDetails;
			if (tempParentDetails.itemType === 7) {
				return checkHandlerForUnSelectableItems(tempParentDetails);
			} else {
				return checkHanderForSelectableItems(tempParentDetails);
			}
		}
	};

	const checkHighlight = (item) =>
		item.itemType === 10 || item.itemType === 3 ? checkHanderForSelectableItems(item) : checkHandlerForUnSelectableItems(item);

	const renderControl = {
		rendercomponent: (item) => (
			<Checkbox
				checked={checkHanderForSelectableItems(item)}
				onChange={(e) => handleToggle(e, item)}
				color="primary"
				dataCy="portal-public"
				tabIndex={-1}
				style={{
					height: "0px",
					width: "0px",
					marginTop: "4px",
				}}
			/>
		),
		isCheckBoxVisible: (item) => item.itemType === 10 || item.itemType === 3,
		highlightCheck: (item) => checkHighlight(item),
		keyDownAction: (e, item) => handleKeyDownForFromAgenda(e),
		isBoardNotesPresent: (item) =>
			item.containsBriefingNotes && (
				<Tooltip title={"includes-briefing-notes"}>
					<span aria-label="includes-briefing-notes" className={classes.briefingNotesSpan}>
						<Icon name="notes" />
					</span>
				</Tooltip>
			),
	};

	const setOverallSelectedItemsForSmartSelect = (
		smartSelectedItems,
		overallSelectedMeetingItems,
		objectForAgendaItems,
		defaultSmartSelectEnabled,
	) => {
		Object.keys(smartSelectedItems).forEach((element) => {
			if (smartSelectedItems[element]) {
				if (
					Array.isArray(overallSelectedMeetingItems[element]) ||
					(typeof overallSelectedMeetingItems[element] === "object" && !objectForAgendaItems[element].isMainParent) ||
					(typeof overallSelectedMeetingItems[element] === "string" && objectForAgendaItems[element].itemType === 10)
				) {
					let itemType = objectForAgendaItems[element].itemType;
					subheadingModifier(
						element,
						defaultSmartSelectEnabled,
						itemType,
						overallSelectedMeetingItems,
						dispatch,
						setOverallSelectedMeetingItems,
					);
				} else if (typeof overallSelectedMeetingItems[element] === "string") {
					childModifier(element, defaultSmartSelectEnabled, overallSelectedMeetingItems, dispatch, setOverallSelectedMeetingItems);
				} else {
					parentModifier(element, defaultSmartSelectEnabled, overallSelectedMeetingItems, dispatch, setOverallSelectedMeetingItems);
				}
			}
		});
	};

	const smartSelectHandler = () => {
		if (!defaultSmartSelectEnabled) {
			telemetryAddEvent(`Copy/Move - ${copyOrMove} - Smart Select Enabled`);
		} else {
			telemetryAddEvent(`Copy/Move - ${copyOrMove} - Smart Select Disabled`);
		}
		dispatch(setSmartSelectToggle(!defaultSmartSelectEnabled));
		setOverallSelectedItemsForSmartSelect(
			smartSelectedItems,
			overallSelectedMeetingItems,
			objectForAgendaItems,
			!defaultSmartSelectEnabled,
		);
		callBackforFromAgendaListPage();
	};

	if (isOverflow && finalRightMeetingAgendaItems?.length > 0) {
		return <ToAgendaListPage callBackforToAgendaListPage={callBackforToAgendaListPage} copyOrMove={copyOrMove} isOverflow={isOverflow} />;
	}

	return agendaItems?.items?.length > 0 && Object.keys(objectForAgendaItems).length > 0 && !isOverflow ? (
		<Container>
			<Box>
				{copyOrMove === "move" &&
					getWarningText(agendaItems.meeting.boardAgendaStatus, agendaItems.meeting.publicAgendaStatus)?.length > 0 && (
						<Warning warnings={getWarningText(agendaItems.meeting.boardAgendaStatus, agendaItems.meeting.publicAgendaStatus)} />
					)}
			</Box>
			<Box className={classes.smartSelectContainer}>
				<Box className={classes.smartSelectText}>{t("app:smartSelectToggle")}</Box>
				<Box className={classes.tabFocusStyling} onKeyDown={handleKeyDownForSwitch} tabIndex={0}>
					<AntSwitch checked={defaultSmartSelectEnabled} onChange={smartSelectHandler} tabIndex={-1} />
				</Box>
			</Box>
			<Box
				className={clsx({
					[classes.agendaItemsList]:
						copyOrMove === "copy" ||
						(copyOrMove === "move" &&
							getWarningText(agendaItems.meeting.boardAgendaStatus, agendaItems.meeting.publicAgendaStatus)?.length === 0),
					[classes.agendaItemsListWithWarning]:
						copyOrMove === "move" &&
						getWarningText(agendaItems.meeting.boardAgendaStatus, agendaItems.meeting.publicAgendaStatus)?.length > 0,
				})}
			>
				<TableOfContents items={agendaItems.items} isPdfSidebar renderControl={renderControl} />
			</Box>
		</Container>
	) : (
		<CircularProgressIndicator minHeight="calc(100vh - 120px)" />
	);
};

export default FromAgendaListPage;
